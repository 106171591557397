import { Popconfirm, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import Http from "../../services/Http";
import { Link } from "react-router-dom";
import ContentLoader from "../../components/loaders/ContentLoader";

export default function AllSubscription() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  const toggleActive = async (_id) => {
    try {
      const res = await Http.post(`/toggleActive/` + _id);
      message.success("Subscription has been toggle activated");
    } catch (error) {
      message.warning("Subscription has been toggle deactivated");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoad(true);
    Http.get("/customers/relish/check-All-subscriptions-status").then((res) => {
      console.log(res?.data?.record);
      setData(res?.data?.record);
      setLoad(false);
    });
  };
  const deleteData = (_id) => {
    setLoad(true);
    Http.delete("/remove/" + _id)
      .then((res) => {
        getData();
        message.success("Data has been deleted");
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(" NO Data has been deleted");
      });
  };
  const columnsProduct = [
    {
      title: "name",
      dataIndex: "planId",
      key: "planId",
      render: (text, record) => <span className="en-font">{text.name.en}</span>,
    },
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => <span className="en-font">{text}</span>,
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => <span className="en-font">{text}</span>,
    },
    {
      title: "subdomain",
      dataIndex: "subdomain",
      key: "subdomain",
      render: (text, record) => <span className="en-font">{record?.domain ? record?.domain : text}</span>,
    },
  ];

  const columns = [
    {
      title: "Name En",
      dataIndex: "planId",
      key: "planId",
      render: (text, record) => <span className="en-font">{text.name.en}</span>,
    },
    {
      title: "Name Ar",
      dataIndex: "planId",
      key: "planId",
      render: (text, record) => <span className="en-font">{text.name.ar}</span>,
    },
    // {
    //   title: "price",
    //   dataIndex: "price",
    //   key: "price",
    //   render: (text) => <span>{text}</span>,
    // },

    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => (
        <span>
          <Switch
            defaultChecked={text}
            onChange={(value) => {
              toggleActive(record._id, value);
            }}
          />
        </span>
      ),
    },
    {
      title: "action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/panel/subscription/${record._id}/edit`} className="btn btn-sm btn-warning">
            <MdEdit />
          </Link>
          <Popconfirm title="Are you sure delete this?" onConfirm={() => deleteData(record?._id)} okText="Yes" cancelText="No">
            <button className="btn btn-sm btn-danger">
              <MdDelete />
            </button>
          </Popconfirm>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Subscription</h6>
          <Link to="/panel/create_subscription" className="btn btn-primary">
            New Subscription
          </Link>
        </div>
        {!load && data.length > 0 && (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 30,
              showSizeChanger: true,
              position: "both",
            }}
          />
        )}
      </div>
      {load && <ContentLoader />}
    </div>
  );
}
