import React, { Component } from "react";
import { Tooltip } from "antd";

import Http from "../services/Http";

class Statics extends Component {
  state = { load: false };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("statistics").then((res) => {
      this.setState({ data: res.data.statistics, load: true });
    });
  };

  render() {
    const { data, load } = this.state;
    return (
      <div className="all-statics">
        {load && (
          <div className="row">
            <div className="col-md-4">
              <div className="admin-card statics-card bg-color1">
                <h2 className="en-font statics-number">
                  {Math.round(data.totalCanceledOrders[0]?.amount ?? 0)} EGP
                </h2>
                <h5>totalCanceledOrders</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="admin-card statics-card bg-color1">
                <h2 className="en-font statics-number">
                  {Math.round(data.totalCompletedOrders[0]?.amount ?? 0)} EGP
                </h2>
                <h5>totalCompletedOrders</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="admin-card statics-card bg-color1">
                <h2 className="en-font statics-number">
                  {Math.round(data.totalPendingOrders[0]?.amount ?? 0)} EGP
                </h2>
                <h5>totalPendingOrders</h5>
              </div>
            </div>

            <div className="col-md-3">
              <div className="admin-card statics-card bg-orange">
                <h2 className="en-font statics-number">{data.products}</h2>
                <h5>product</h5>
              </div>
            </div>

            <div className="col-md-3">
              <div className="admin-card statics-card bg-color3">
                <h2 className="en-font statics-number">{data.admins}</h2>
                <h5>customers</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="admin-card statics-card bg-color3">
                <h2 className="en-font statics-number">{data.users}</h2>
                <h5>users</h5>
              </div>
            </div>

            <div className="col-md-3">
              <div className="admin-card statics-card bg-color1">
                <h2 className="en-font statics-number">{data.pendingOrders}</h2>
                <h5>pendingOrders</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="admin-card statics-card bg-color1">
                <h2 className="en-font statics-number">
                  {data.canceledOrders}
                </h2>
                <h5>canceledOrders</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="admin-card statics-card bg-color1">
                <h2 className="en-font statics-number">
                  {data.completedOrders}
                </h2>
                <h5>completedOrders</h5>
              </div>
            </div>
            <div className="col-md-12 product-most">
              <div className="">
                <div className="card-header">
                  <h5 className="text-center">mostOrders</h5>
                </div>
                {data.mostOrdered?.map((product) => (
                  <>
                    <div className="row align-items-center p-2">
                      <div className="col-md-3">
                        <img
                          src={
                            product.gallaries[0]
                              ? Http.front_url + product.gallaries[0]
                              : "/img/placeholder.png"
                          }
                          alt="product"
                          width="50"
                          height="50"
                        />
                      </div>
                      <div className="col-md-7">
                        <h6>
                          {product.name["en"]
                            ? product.name["en"]
                            : product.name["ar"]}
                        </h6>
                      </div>
                      <div className="col-md-2">
                        <h6>{product.orders}</h6>
                      </div>
                    </div>
                    <hr />
                  </>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Statics;
