import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import Http from "../../services/Http";
import ContentLoader from "../../components/loaders/ContentLoader";

class Records extends Component {
  state = {
    stuff: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("stuff").then((res) => {
      res.data.stuff.map((stuff) => (stuff.key = stuff._id));
      this.setState({
        stuff: res.data.stuff,
        load: true,
      });
    });
  };

  handleRole = (text) => {
    switch (text) {
      case 1:
        return "super";
        break;
      case 2:
        return "developer";
        break;
      case 3:
        return "content";
        break;

      default:
        break;
    }
  };

  deleteRecord = (id) => {
    Http.delete("stuff/" + id)
      .then((res) => {
        this.getData();
        message.success("stuff has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  render() {
    const { stuff, load } = this.state;

    const columns = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "username",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "role",
        dataIndex: "role",
        key: "role",
        render: (text) => <span>{this.handleRole(text)}</span>,
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/panel/stuff/${record._id}/edit/`}
              className="btn btn-sm btn-warning"
            >
              <MdEdit />
            </Link>

            <Popconfirm
              title="Are you sure delete this?"
              onConfirm={() => this.deleteRecord(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-stuff admin-card pd">
          <div className="admin-title">
            <h6>stuff</h6>
            <Link to="/panel/stuff/add" className="btn btn-primary">
              New stuff
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={stuff}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
