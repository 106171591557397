import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../../components/forms/Input";
import { openNoti } from "../../../components/confirmation-popup/Notification";
import ReactFileReader from "react-file-reader";
import Http from "../../../services/Http";

class UpdateRecord extends Component {
  state = {
    code: 0,
    type: "",
    types: [],
    cover: false,
    preview: false,
    errors: {},
    load: true,
    btnLoad: false,
  };

  componentDidMount() {
    Http.get("types").then((res) => {
      this.setState({ types: res.data.data });
    });
    Http.get("components/" + this.props.match.params.id).then((res) => {
      this.setState({
        code: res.data.data.code,
        type: res.data.data.type,
        preview: Http.front_url + res.data.data.cover,
        load: false,
      });
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ cover: files[0], preview: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ cover: false, preview: false });

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    const payload = new FormData();
    if (this.state.cover) payload.append("cover", this.state.cover);
    payload.append("code", this.state.code);
    payload.append("type", this.state.type);

    Http.put("components/" + this.props.match.params.id, payload)
      .then(async (res) => {
        openNoti(res.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/components");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const { errors, btnLoad, preview, types, code, type, load } = this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-title">
          <h6>Update Component</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/components" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>
        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createRecord}>
              <div className="row align-items-center">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <ReactFileReader
                    handleFiles={this.handleImage}
                    fileTypes={[".jpg", ".png", "jpeg"]}
                  >
                    <div className="form-group">
                      <div className="image-preview single-blog">
                        <img
                          src={preview.length > 0 ? preview : "/img/logo.png"}
                          alt="motajer"
                          title="image"
                        />
                      </div>
                    </div>
                  </ReactFileReader>
                  {preview && (
                    <button className="remove-image" onClick={this.clearLogo}>
                      <DeleteOutlined />
                    </button>
                  )}
                </div>
                <div className="col-md-3"></div>

                <div className="col-md-6">
                  <Input
                    label="Code"
                    type="number"
                    defaultValue={code}
                    required
                    error={errors["code"] ? errors["code"].message : false}
                    onChange={this.getData}
                    name="code"
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Type"
                    type="normal-select"
                    options={types}
                    defaultValue={type}
                    fOption="select component type"
                    required
                    error={errors["type"] ? errors["type"].message : false}
                    onChange={this.getData}
                    name="type"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(UpdateRecord);
