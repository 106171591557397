import React from "react";
import { Route } from "react-router-dom";
import { userRole } from "./user";
import { checkToken } from "../helpers/checkToken";

const OwnerRoute = ({ path, component: Component }) => {
  const GoToPage = () => {
    window.location.replace("/panel/login");
  };

  const checkOwner = () => {
    return userRole() != 2 || userRole() != 1;
  };

  return (
    <Route
      path={path}
      render={() => {
        if (!checkOwner() && checkToken()) {
          return <GoToPage />;
        }
        return <Component />;
      }}
    />
  );
};

export default OwnerRoute;
