import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import { openNoti } from "../../components/confirmation-popup/Notification";
import Input from "../../components/forms/Input";

class EditRecord extends Component {
  state = {
    id: 0,
    code: 0,
    mainColor: "",
    secondColor: "",
    data: {},
    name: "",
    nameAr: "",
    load: false,
    categories: [],
    btnLoad: false,
  };

  async componentDidMount() {
    const id = this.props.match.params.id;

    const { data } = await Http.get("industries/" + id);
    this.setState({
      id: id,
      name: data.industry.name,
      nameAr: data.industry.nameAr,
      code: data.industry.code,
      categories: data.industry.categories,
      mainColor: data.industry.colors?.main,
      secondColor: data.industry.colors?.second,
      load: true,
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  editModel = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });
    // Update Category
    const { id } = this.state;
    const industry = {
      name: this.state.name,
      nameAr: this.state.nameAr,
      code: this.state.code,
      colors: {
        main: this.state.mainColor,
        second: this.state.secondColor,
      },
      categories: this.state.categories,
    };

    await Http.put("industries/" + id, industry)
      .then(async (result) => {
        openNoti("industry updated successfully");
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/industries");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  addChildren = () => {
    this.setState({
      categories: [...this.state.categories, { nameEn: "", nameAr: "" }],
    });
  };

  removeChild = (index) => {
    this.setState({
      categories: this.state["categories"].filter((item, i) => i !== index),
    });
  };

  changeChild = (index, event) => {
    let clone = [...this.state.categories];

    if (["nameAr", "nameEn"].includes(event.target.name)) {
      if (clone?.[index]?.["name"]) clone[index]["name"][event.target.name === "nameAr" ? "ar" : "en"] = event.target.value;
      else clone[index] = { name: { [event.target.name === "nameAr" ? "ar" : "en"]: event.target.value } };
    } else clone[index][event.target.name] = event.target.value;

    this.setState({
      categories: clone,
    });
  };

  render() {
    const { load, mainColor, secondColor, name, code, btnLoad, nameAr, industries } = this.state;
    return (
      <div className="wrapper">
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Edit industry</h6>
            <div className="admin-title-super2">
              <button type="submit" disabled={btnLoad} onClick={this.editModel} className="btn btn-primary form-button">
                {btnLoad ? (
                  <span>
                    <LoadingOutlined /> Update
                  </span>
                ) : (
                  "Update"
                )}
              </button>

              <Link to="/panel/industries" className="btn btn-warning">
                Cancel
              </Link>
            </div>
          </div>

          {load && (
            <div className="admin-form">
              <form onSubmit={this.editModel}>
                <div className="row">
                  <div className="col-md-6">
                    <Input label="Name" placeholder="Name" type="text" required defaultValue={name} onChange={this.getData} name="name" />
                  </div>
                  <div className="col-md-6">
                    <Input label="Name ar" placeholder="Name" type="text" required value={nameAr} onChange={this.getData} name="nameAr" />
                  </div>
                  <div className="col-md-4">
                    <Input label="Code" placeholder="Code" type="text" required defaultValue={code} onChange={this.getData} name="code" />
                  </div>
                  <div className="col-md-4">
                    <Input label="Main color" type="color" required onChange={this.getData} defaultValue={mainColor} name="mainColor" />
                  </div>
                  <div className="col-md-4">
                    <Input label="Second color" type="color" required onChange={this.getData} defaultValue={secondColor} name="secondColor" />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="admin-card pd">
          <div className="admin-title">
            <h6>Categories</h6>
            <button onClick={this.addChildren} className="btn btn-sm btn-primary">
              +
            </button>
          </div>

          {this.state.categories.map((child, index) => (
            <div className="row align-items-center">
              <div className="col-md-5">
                <Input
                  label="Name"
                  placeholder="Name"
                  type="text"
                  required
                  disabled={child?._id?.length > 0}
                  onChange={(event) => this.changeChild(index, event)}
                  name="nameEn"
                  value={child["name"]?.["en"]}
                />
              </div>
              <div className="col-md-5">
                <Input
                  label="Name ar"
                  placeholder="Name"
                  type="text"
                  required
                  disabled={child?._id?.length > 0}
                  onChange={(event) => this.changeChild(index, event)}
                  name="nameAr"
                  value={child["name"]?.["ar"]}
                />
              </div>
              <div className="col-md-2">
                {!child?._id && (
                  <button className="btn btn-danger btn-sm" onClick={() => this.removeChild(index)}>
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(EditRecord);
