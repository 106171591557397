import jwt from "jsonwebtoken";

export function userRole(coin) {
  const token = coin ? coin : window.localStorage.getItem("super_token");
  if (token) {
    let user = jwt.decode(token);
    return user.role;
  } else return false;
}

export function logout() {
  window.localStorage.removeItem("super_token");
  window.location.replace("/panel/login");
}
