import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import Http from "../../services/Http";
import { Button, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ContentLoader from "../../components/loaders/ContentLoader";

function EditAffiliate({ match }) {
  const [file, setFile] = useState("");
  const [error, setError] = useState({});
  const { id } = match?.params;
  const [load, setLoad] = useState(false);
  //   const { id } = matchPath();

  const [formData, setFormData] = useState({
    email: ``,
    name: ``,
    phone: ``,
    country: ``,
    city: ``,
    password: ``,
    companyname: ``,
    website: ``,
    wallet: ``,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState([]);
  const [all, setAll] = useState([]);
  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoad(true);

    Http.get("/allCustomers").then((res) => {
      setCustomers(res?.data);

      res?.data?.map((item) => {
        item.label = item.adminName;
        item.value = item?._id;
      });

      Http.get(`/listAffiliate/` + id).then((res2) => {
        let selectedCustomers = [];

        res?.data.map((item) => {
          if (res2.data.record.customers.includes(item?._id)) selectedCustomers.push(item);
        });

        console.log(selectedCustomers);

        setAll(selectedCustomers);

        setFormData({
          ...formData,
          name: res2?.data?.record?.name,
          email: res2?.data?.record?.email,
          phone: res2?.data?.record?.phone,
          country: res2?.data?.record?.country,
          city: res2?.data?.record?.city,
          companyname: res2?.data?.record?.companyname,
          website: res2?.data?.record?.website,
          wallet: res2?.data?.record?.wallet,
          commisionInitial: res2?.data?.record?.commision?.initial,
          commisionRecurring: res2?.data?.record?.commision?.recurring,
        });

        setLoad(false);
      });
    });
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (formData.name.trim() === "") {
      newErrors.name = "Please enter your name";
      valid = false;
    }
    if (formData.phone.trim() === "") {
      newErrors.phone = "Please enter your phone";
      valid = false;
    }
    if (formData.country.trim() === "") {
      newErrors.country = "Please enter your country";
      valid = false;
    }
    // if (formData.city.trim() === "") {
    //   newErrors.city = "Please enter your city";
    //   valid = false;
    // }
    if (formData.password.trim() === "") {
      newErrors.password = "Please enter your password";
      valid = false;
    }
    if (formData.companyname.trim() === "") {
      newErrors.companyname = "Please enter your companyname";
      valid = false;
    }
    // if (formData.website.trim() === "") {
    //   newErrors.website = "Please enter your website";
    //   valid = false;
    // }
    // if (formData.wallet.trim() === "") {
    //   newErrors.wallet = "Please enter your wallet";
    //   valid = false;
    // }
    if (formData.email.trim() === "") {
      newErrors.email = "Please enter your email";
      valid = false;
    }
    setError(newErrors);
    return valid;
  };

  const runSubscribe = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoad(true);

      let payload = {
        ...formData,
        customers: all.map((item) => item.value),
        commision: {
          initial: formData?.commisionInitial,
          recurring: formData?.commisionRecurring,
        },
      };

      Http.post(`/updateAffiliate/` + id, payload)
        .then(() => {
          message.success("The operation was successful");
          setLoad(false);
        })
        .catch((error) => {
          message.error("The operation failed");
          console.error("Error:", error);
        });
    }
  };

  const fd = new FormData();
  fd.append("file", file);
  const handelfille = (e) => {
    setFile(e?.file?.originFileObj);
    console.log(e?.file?.originFileObj);
  };
  const { Option } = Select;
  const handleChange = (value, allValues) => {
    console.log(allValues);
    setAll(allValues);
  };
  return (
    <div>
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Update Affiliate</h6>
          <div className="admin-title-super2">
            <Link to="/panel/affiliate" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>
        {!load && (
          <>
            {" "}
            <div className=" grid grid-cols-1 sm:grid-cols-4 gap-5">
              <div className=" flex flex-col">
                <label htmlFor="">Name</label>
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="name"
                  name="name"
                  placeholder="  name"
                  value={formData?.name}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                />
                {error.name && <span className="text-red-500 mb-2 text-center mt-3">{error.name}</span>}
              </div>
              <div className=" flex flex-col">
                <label htmlFor="">Email</label>
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="email"
                  name="email"
                  value={formData?.email}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large "
                  placeholder="  email"
                />
                {error.email && <span className="text-red-500 mb-2 text-center mt-3">{error.email}</span>}
              </div>
              <div className="  flex flex-col">
                <label htmlFor="">Phone</label>
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                  placeholder="  phone"
                />
                {error.phone && <span className="text-red-500 mb-2 text-center mt-3">{error.phone}</span>}
              </div>
              <div className="  flex flex-col">
                <label htmlFor="">Country</label>
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="country"
                  name="country"
                  value={formData.country}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  placeholder="  country"
                />
                {error.country && <span className="text-red-500 mb-2 text-center mt-3">{error.country}</span>}
              </div>
              <div className="  flex flex-col">
                <label htmlFor="">City</label>
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="city"
                  name="city"
                  value={formData.city}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                  placeholder="  city"
                />
                {/* {error.city && <span className="text-red-500 mb-2 text-center mt-3">{error.city}</span>} */}
              </div>

              <div className="flex flex-col">
                <label htmlFor="">Password</label>{" "}
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                  placeholder="  password"
                />
                {error.password && <span className="text-red-500 mb-2 text-center mt-3">{error.password}</span>}
              </div>
              <div className="  flex flex-col">
                <label htmlFor="">Company name</label>{" "}
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="companyname"
                  name="companyname"
                  value={formData.companyname}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                  placeholder="  companyname"
                />
                {error.companyname && <span className="text-red-500 mb-2 text-center mt-3">{error.companyname}</span>}
              </div>
              <div className="  flex flex-col">
                <label htmlFor="">Website</label>{" "}
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="website"
                  name="website"
                  value={formData.website}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                  placeholder="  website"
                />
                {error.website && <span className="text-red-500 mb-2 text-center mt-3">{error.website}</span>}
              </div>
              <div className="  flex flex-col">
                <label htmlFor="">Wallet</label>
                <input
                  className=" border py-2 px-3  rounded-md focus:outline-none"
                  type="number"
                  name="wallet"
                  value={formData.wallet}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                  placeholder="  wallet"
                />
                {error.wallet && <span className="text-red-500 mb-2 text-center mt-3">{error.wallet}</span>}
              </div>

              <div className="flex flex-col">
                <label htmlFor="">Commision init</label>
                <input
                  className="border py-2 px-3  rounded-md focus:outline-none"
                  type="number"
                  name="commisionInitial"
                  value={formData.commisionInitial}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                />
                {error.commisionInitial && <span className="text-red-500 mb-2 text-center mt-3">{error.commisionInitial}</span>}
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Commision recurring</label>
                <input
                  className="border py-2 px-3  rounded-md focus:outline-none"
                  type="number"
                  name="commisionRecurring"
                  value={formData.commisionRecurring}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                  size="large"
                />
                {error.commisionRecurring && <span className="text-red-500 mb-2 text-center mt-3">{error.commisionRecurring}</span>}
              </div>

              <div className="">
                <label htmlFor="">Select</label>
                <Select
                  className=" !py-2 !rounded-md"
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="select customers"
                  value={all}
                  onChange={handleChange}
                >
                  {customers?.map((item, index) => (
                    <Option value={item?._id} key={item?._id} label={item?.adminName}>
                      {item?.adminName}
                    </Option>
                  ))}
                </Select>
              </div>

              {/* <div className=" flex flex-col !py-2 ">
            <label htmlFor="">Upload file</label>
            <Upload onChange={handelfille}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </div> */}
            </div>
            <div
              onClick={runSubscribe}
              className=" my-4 py-2 px-4 rounded-md !bg-cyan-500 !text-white text-center cursor-pointer flex justify-center items-center "
              type="submit"
            >
              Submit
            </div>
          </>
        )}
      </div>
      {load && <ContentLoader />}
    </div>
  );
}

export default withRouter(EditAffiliate);
