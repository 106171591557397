import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import { Editor } from "@tinymce/tinymce-react";
import ReactFileReader from "react-file-reader";

class AddRecord extends Component {
  state = {
    name: "",
    link: "",
    cover: false,
    preview: false,
    errors: {},
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ cover: files[0], preview: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ cover: false, preview: false });

  createRecord = async (event) => {
    const url =
      window.location.hostname.includes("localhost") || window.location.hostname.includes("morseko")
        ? "http://localhost:8000/website/api/v1/"
        : "https://motajer.net/website/api/v1/";

    let header = {
      "x-app-token": "motajerWebsiteToken",
    };

    event.preventDefault();
    this.setState({ btnLoad: true });

    const work = {
      name: this.state.name,
      link: this.state.link,
    };

    fetch(url + "works", {
      method: "POST",
      headers: {
        "x-app-token": header["x-app-token"],
        "Content-Type": "application/json",
      },
      body: JSON.stringify(work),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (this.state.cover) {
          let payload = new FormData();
          payload.append("cover", this.state.cover);

          await fetch(url + "works/" + res.work._id, {
            method: "PUT",
            headers: header,
            body: payload,
          });
        }
        openNoti(res.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/works");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const { errors, btnLoad, preview } = this.state;
    return (
      <div className="all-works admin-card pd">
        <div className="admin-title">
          <h6>Add work</h6>
          <div className="admin-title-super2">
            <button type="submit" disabled={btnLoad} onClick={this.createRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/works" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <ReactFileReader handleFiles={this.handleImage} fileTypes={[".jpg", ".png", "jpeg"]}>
                  <div className="form-group">
                    <div className="image-preview single-work">
                      <img src={preview.length > 0 ? preview : "/img/logo.png"} alt="motajer" title="image" />
                    </div>
                  </div>
                </ReactFileReader>
                {preview && (
                  <button className="remove-image" onClick={this.clearLogo}>
                    <DeleteOutlined />
                  </button>
                )}
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-6">
                <Input
                  label="Name"
                  type="text"
                  required
                  error={errors["name"] ? errors["name"].message : false}
                  onChange={this.getData}
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Link"
                  type="text"
                  required
                  error={errors["link"] ? errors["link"].message : false}
                  onChange={this.getData}
                  name="link"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
