import React, { Component } from "react";
import { userRole } from "../services/user";
import Http from "../services/Http";
import { Alert } from "antd";
import { message } from "antd";

class adminLogin extends Component {
  state = {
    login: "",
    password: "",
    errors: "",
  };

  login = (event) => {
    event.preventDefault();

    // Validation
    if (this.state.login.length <= 0) message.warning("login can't be blank");
    else if (this.state.password.length <= 0)
      message.warning("Password can't be blank");
    else {
      const { login, password } = this.state;
      let user = {
        login,
        password,
      };
      Http.post("stuff/login", user)
        .then((res) => {
          message.success(res.data.message);
          localStorage.setItem("super_token", res.data.token);

          setTimeout(() => {
            if (userRole(res.data.token) == 3)
              window.location.replace("/panel/blogs");
            else window.location.replace("/panel");
          }, 300);
        })
        .catch((error) => {
          console.log(error);
          message.warning(error.response.data.message);
        });
    }
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { errors } = this.state;
    return (
      <section className="adminLogin login-page">
        <div className="container">
          {errors.length > 0 && (
            <Alert
              message="Errors"
              description={<p> {errors + "\n"} </p>}
              type="error"
            />
          )}
          <br /> <br />
          <div className="login-form">
            <img
              src="/img/logo.png"
              className="motajer-logo mb-5"
              alt="motajer"
              width="150"
            />
            <form className="form-login-admin" onSubmit={this.login}>
              <div className="form-group">
                <label htmlFor="">Supdomain or Email</label>
                <input
                  type="text"
                  name="login"
                  required
                  onChange={this.getData}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  name="password"
                  required
                  onChange={this.getData}
                  className="form-control"
                />
              </div>
              <button type="submit" className="submit-btn motajer-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default adminLogin;
