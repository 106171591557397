import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";

class AddRecord extends Component {
  state = {
    value: "",
    notes: "",
    type: 2,
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new movement
    const movement = {
      value: this.state.value,
      notes: this.state.notes,
      type: Number(this.state.type),
    };

    await Http.post("movements", movement)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/movements");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { btnLoad } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Add movement</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/movements" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createBrand}>
            <div className="row align-items-center">
              <div className="col-md-4">
                <Input
                  label="Value"
                  type="number"
                  required
                  onChange={this.getData}
                  name="value"
                />
              </div>
              <div className="col-md-4">
                <Input
                  label="Notes"
                  type="text"
                  required
                  onChange={this.getData}
                  name="notes"
                />
              </div>
              <div className="col-md-4">
                <Checkbox
                  onChange={(event) =>
                    this.setState({ type: event.target.checked ? 1 : 2 })
                  }
                >
                  Is Revenue
                </Checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
