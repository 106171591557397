import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import ReactFileReader from "react-file-reader";
import { message } from "antd";

class AddRecord extends Component {
  state = {
    adminName: "",
    marketName: "",
    categories: [],
    category: "",
    phone: 0,
    logoImage: "",
    logo: "",
    email: 0,
    password: "",
    subdomain: "",
    domain: "",
    plan: "",
    load: true,
    plans: [],
    errors: {},
    btnLoad: false,
  };

  componentDidMount() {
    Promise.all([Http.get("plans"), Http.get("industries")]).then((res) => {
      this.setState({
        plans: res[0].data.plans,
        categories: res[1].data.industries,
        load: false,
      });
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ logo: files[0], logoImage: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ logo: false, logoImage: false });

  createRecord = async (event) => {
    const { adminName, marketName, phone, email, password, domain, plan, logo, category, industry } = this.state;

    event.preventDefault();

    if (
      adminName &&
      adminName?.length > 2 &&
      marketName &&
      marketName?.length > 2 &&
      email &&
      email?.length > 6 &&
      password &&
      password?.length > 6
    ) {
      this.setState({ btnLoad: true });

      // Create new brand
      const customer = {
        adminName,
        marketName,
        phone,
        email,
        password,
        domain,
        plan,
      };

      if (industry) customer.industry = industry;

      await Http.post("customers/", customer)
        .then(async (result) => {
          if (logo) {
            const data = new FormData();
            data.append("logo", logo);
            try {
              await Http.put("customers/" + result.data.customer._id, data);
            } catch (err) {
              console.log(err);
            }
          }
          openNoti(result.data.message);
          this.setState({ btnLoad: false });
          this.props.history.push("/panel/customers");
        })
        .catch((err) => {
          if (err.response.data.message) message.warning(err.response.data.message);
          this.setState({ errors: err.response.data.errors, btnLoad: false });
        });
    }
  };

  render() {
    const { errors, btnLoad, load, logoImage, categories } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Add customer</h6>
          <div className="admin-title-super2">
            <Link to="/panel/customers" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createRecord}>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <ReactFileReader handleFiles={this.handleImage} fileTypes={[".jpg", ".png"]}>
                    <div className="form-group">
                      <div className="image-preview single">
                        <img src={logoImage.length > 0 ? logoImage : "/img/placeholder.png"} alt="motajer" title="image" />
                      </div>
                    </div>
                  </ReactFileReader>
                  {logoImage && (
                    <button className="remove-image" onClick={this.clearLogo}>
                      <DeleteOutlined />
                    </button>
                  )}
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        label="Category"
                        type="getOption"
                        options={categories}
                        fOption="select industry"
                        error={errors["industry"] ? errors["industry"].message : false}
                        onChange={this.getData}
                        name="industry"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Market name"
                        placeholder="Motajer"
                        type="text"
                        required
                        error={errors["marketName"] ? errors["marketName"].message : false}
                        onChange={this.getData}
                        name="marketName"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Admin Name"
                        placeholder="Admin Name"
                        type="text"
                        required
                        error={errors["adminName"] ? errors["adminName"].message : false}
                        onChange={this.getData}
                        name="adminName"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Email"
                        placeholder="email"
                        type="email"
                        required
                        error={errors["email"] ? errors["email"].message : false}
                        onChange={this.getData}
                        name="email"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Phone"
                        placeholder="phone"
                        type="tel"
                        required
                        error={errors["phone"] ? errors["phone"].message : false}
                        onChange={this.getData}
                        name="phone"
                      />
                    </div>
                    {/* <div className="col-md-3">
                      <Input
                        label="Subdomain"
                        placeholder="subdomain"
                        type="text"
                        required
                        error={errors["subdomain"] ? errors["subdomain"].message : false}
                        onChange={this.getData}
                        name="subdomain"
                      />
                    </div> */}
                    <div className="col-md-3">
                      <Input
                        label="Domain"
                        placeholder="domain"
                        type="text"
                        error={errors["domain"] ? errors["domain"].message : false}
                        onChange={this.getData}
                        name="domain"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Password"
                        placeholder="password"
                        type="password"
                        required
                        error={errors["password"] ? errors["password"].message : false}
                        onChange={this.getData}
                        name="password"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Plan"
                        type="select"
                        fOption="Select plan"
                        options={this.state.plans}
                        required
                        error={errors["plan"] ? errors["plan"].message : false}
                        onChange={this.getData}
                        name="plan"
                      />
                    </div>
                  </div>
                  <button type="submit" disabled={btnLoad} className="btn btn-primary form-button d-block width-100">
                    {btnLoad ? (
                      <span>
                        <LoadingOutlined /> Load..
                      </span>
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AddRecord);
