import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../../components/forms/Input";
import { openNoti } from "../../../components/confirmation-popup/Notification";
import { Editor } from "@tinymce/tinymce-react";
import Http from "../../../services/Http";

class EditRecord extends Component {
  state = {
    "name.ar": "",
    "name.en": "",
    "content.ar": "",
    "content.en": "",
    departments: [],
    department: "",
    btnLoad: false,
  };

  async componentDidMount() {
    const { data } = await Http.get("onDeskCategories");

    data.record.map((item) => (item.name = item.name.en));
    data.record.map((item) => (item.value = item._id));

    this.setState({ departments: data.record });

    const blog = await Http.get(`onDeskBlogs/${this.props.match.params.id}`);

    this.setState({
      "name.ar": blog?.data?.record.name?.ar,
      "name.en": blog?.data?.record.name?.en,
      department: blog?.data?.record.category?._id,
      "content.ar": blog?.data?.record.content.ar,
      "content.en": blog?.data?.record.content.en,
    });
    setTimeout(() => {
      this.setState({
        "content.ar": blog?.data?.record.content.ar,
        "content.en": blog?.data?.record.content.en,
      });
    }, 10000);
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    const payload = {
      name: { ar: this.state["name.ar"], en: this.state["name.en"] },
      content: { ar: this.state["content.ar"], en: this.state["content.en"] },
      category: this.state.department,
    };

    const { data } = await Http.put(`onDeskBlogs/${this.props.match.params.id}`, payload);

    openNoti(data?.message);
    this.setState({ btnLoad: false });
    this.props.history.push("/panel/docs");
  };

  render() {
    const { btnLoad } = this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-name">
          <h6>Update help desk blog</h6>
          <div className="admin-name-super2">
            <button type="submit" disabled={btnLoad} onClick={this.updateRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/docs" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-12 mt-3">
                <Input
                  label="Select department"
                  required
                  name="department"
                  type="select3"
                  value={this.state.department}
                  fOption="Select departments"
                  onChange={this.getData}
                  options={this.state.departments ? this.state.departments : []}
                />
              </div>

              <div className="col-md-6">
                <Input label="name ar" type="text" required value={this.state["name.ar"]} onChange={this.getData} name="name.ar" />
              </div>
              <div className="col-md-6">
                <Input label="name en" type="text" required value={this.state["name.en"]} onChange={this.getData} name="name.en" />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Content Ar</label>
                  <Editor
                    value={this.state["content.ar"]}
                    apiKey="lase8gh50s3eano5gluqxsg4lghx4d8gshv0xw0gn4sejanq"
                    init={{
                      height: 300,
                      menubar: false,

                      plugins: [
                        "advlist autolink lists emoticons directionality link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen image media",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor  | \
             alignleft aligncenter alignright alignjustify ltr rtl emoticons image link media| \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(editor, content) => {
                      this.setState({ "content.ar": editor });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Content En</label>
                  <Editor
                    value={this.state["content.en"]}
                    apiKey="lase8gh50s3eano5gluqxsg4lghx4d8gshv0xw0gn4sejanq"
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists emoticons directionality link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen image media",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor  | \
             alignleft aligncenter alignright alignjustify ltr rtl emoticons image link media| \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(editor, content) => {
                      this.setState({ "content.en": editor });
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EditRecord);
