import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";

class AddRecord extends Component {
  state = {
    name: "",
    phone: "",
    role: "",
    username: "",
    password: "",
    errors: {},
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new brand
    const record = {
      name: this.state.name,
      phone: this.state.phone,
      username: this.state.username,
      role: this.state.role,
    };

    if (this.state.password && this.state.password.length > 0)
      record.password = this.state.password;

    await Http.post("stuff", record)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/stuff");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { errors, btnLoad } = this.state;
    return (
      <div className="all-stuff admin-card pd">
        <div className="admin-title">
          <h6>Add Stuff</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/stuff" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createBrand}>
            <div className="row align-items-center">
              <div className="col-md-4">
                <Input
                  label="Name"
                  type="text"
                  required
                  error={errors["name"] ? errors["name"].message : false}
                  onChange={this.getData}
                  name="name"
                />
              </div>
              <div className="col-md-4">
                <Input
                  label="phone"
                  type="text"
                  required
                  error={errors["phone"] ? errors["phone"].message : false}
                  onChange={this.getData}
                  name="phone"
                />
              </div>
              <div className="col-md-4">
                <Input
                  label="Roles"
                  type="select3"
                  required
                  options={[
                    { name: "super", value: "1" },
                    { name: "develop", value: "2" },
                    { name: "content", value: "3" },
                  ]}
                  error={errors["phone"] ? errors["phone"].message : false}
                  onChange={this.getData}
                  name="role"
                />
              </div>
              <div className="col-md-4">
                <Input
                  label="username"
                  type="text"
                  required
                  error={
                    errors["username"] ? errors["username"].message : false
                  }
                  onChange={this.getData}
                  name="username"
                />
              </div>
              <div className="col-md-4">
                <Input
                  label="password"
                  type="password"
                  required
                  error={
                    errors["password"] ? errors["password"].message : false
                  }
                  onChange={this.getData}
                  name="password"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
