import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../../components/forms/Input";
import { openNoti } from "../../../components/confirmation-popup/Notification";
import ReactFileReader from "react-file-reader";
import Http from "../../../services/Http";

class UpdateRecord extends Component {
  state = {
    code: 0,
    type: "",
    views: [],
    unites: [],
    errors: {},
    load: true,
    btnLoad: false,
  };

  componentDidMount() {
    Http.get("types").then((res) => {
      this.setState({ types: res.data.data });
    });
    Http.get("blocks/" + this.props.match.params.id).then((res) => {
      this.setState({
        code: res.data.data.code,
        type: res.data.data.type,
        unites: res.data.data.unites,
        views: res.data.data.views,
        load: false,
      });
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ cover: files[0], preview: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ cover: false, preview: false });

  addView = () => {
    const clone = { ...this.state };
    clone.views.push({
      name: "",
      cover: "",
    });
    this.setState(clone);
  };

  addUnit = () => {
    const clone = { ...this.state };
    clone.unites.push({
      name: "",
      cover: "",
    });
    this.setState(clone);
  };

  handleViews = (index, event) => {
    const clone = { ...this.state };
    clone.views[index][event.target.name] = event.target.value;
    this.setState(clone);
  };

  handleUnites = (index, event) => {
    const clone = { ...this.state };
    clone.unites[index][event.target.name] = event.target.value;
    this.setState(clone);
  };

  uploadFile = (type, index, files) => {
    const payload = new FormData();
    payload.append("cover", files[0]);

    Http.post("blocks/files", payload).then((res) => {
      const clone = { ...this.state };
      clone[type][index].cover = res.data.file.path;
      this.setState(clone);
    });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    Http.put("blocks/" + this.props.match.params.id, this.state)
      .then(async (res) => {
        openNoti(res.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/blocks");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const { errors, btnLoad, preview, types, code, type, load, unites, views } =
      this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-title">
          <h6>Update Block</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/blocks" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>
        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createRecord}>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <Input
                    label="Code"
                    type="number"
                    defaultValue={code}
                    required
                    error={errors["code"] ? errors["code"].message : false}
                    onChange={this.getData}
                    name="code"
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Type"
                    type="normal-select"
                    options={types}
                    defaultValue={type}
                    fOption="select component type"
                    required
                    error={errors["type"] ? errors["type"].message : false}
                    onChange={this.getData}
                    name="type"
                  />
                </div>
              </div>

              <div className="admin-title">
                <h6 className="font-weight-bold">Views</h6>
                <button
                  type="button"
                  onClick={this.addView}
                  className="btn btn-primary form-button"
                >
                  +
                </button>
              </div>

              <div className="views">
                {views.map((view, viewIndex) => (
                  <div className="row">
                    <div className="col-md-6">
                      <ReactFileReader
                        handleFiles={(files) =>
                          this.uploadFile("views", viewIndex, files)
                        }
                        fileTypes={[".jpg", ".png", "jpeg"]}
                      >
                        <div className="form-group">
                          <div className="image-preview single-blog">
                            <img
                              src={
                                view.cover.length > 0
                                  ? Http.front_url + view.cover
                                  : "/img/logo.png"
                              }
                              alt="motajer"
                              title="image"
                            />
                          </div>
                        </div>
                      </ReactFileReader>
                    </div>
                    <div className="col-md-6">
                      <Input
                        label="Name"
                        type="text"
                        required
                        value={view.name}
                        onChange={(event) => this.handleViews(viewIndex, event)}
                        name="name"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="admin-title">
                <h6 className="font-weight-bold">Unites</h6>
                <button
                  type="button"
                  onClick={this.addUnit}
                  className="btn btn-primary form-button"
                >
                  +
                </button>
              </div>

              <div className="views">
                {unites.map((unite, uniteIndex) => (
                  <div className="row">
                    <div className="col-md-6">
                      <ReactFileReader
                        handleFiles={(files) =>
                          this.uploadFile("unites", uniteIndex, files)
                        }
                        fileTypes={[".jpg", ".png", "jpeg"]}
                      >
                        <div className="form-group">
                          <div className="image-preview single-blog">
                            <img
                              src={
                                unite.cover.length > 0
                                  ? Http.front_url + unite.cover
                                  : "/img/logo.png"
                              }
                              alt="motajer"
                              title="image"
                            />
                          </div>
                        </div>
                      </ReactFileReader>
                    </div>
                    <div className="col-md-6">
                      <Input
                        label="Name"
                        type="text"
                        required
                        value={unite.name}
                        onChange={(event) =>
                          this.handleUnites(uniteIndex, event)
                        }
                        name="name"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(UpdateRecord);
