import React from "react";
import { Route } from "react-router-dom";
import { userRole } from "./user";
import { checkToken } from "../helpers/checkToken";

const ProtectRoute = ({ path, component: Component }) => {
  const GoToPage = () => {
    window.location.replace("/panel/login");
  };

  const checkUser = () => {
    return !userRole() ? false : true;
  };

  return (
    <Route
      path={path}
      render={() => {
        if (!checkUser() && checkToken()) {
          return <GoToPage />;
        }
        return <Component />;
      }}
    />
  );
};

export default ProtectRoute;
