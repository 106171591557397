import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table, Checkbox } from "antd";
import { MdCloud, MdDelete, MdEdit } from "react-icons/md";
import ContentLoader from "../../components/loaders/ContentLoader";
import { website_url } from "../../helpers/data";

class Records extends Component {
  state = {
    blogs: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const url =
      window.location.hostname.includes("localhost") || window.location.hostname.includes("morseko")
        ? "http://localhost:8000/website/api/v1/"
        : "https://motajer.net/website/api/v1/";

    let header = {
      "x-app-token": "motajerWebsiteToken",
    };

    fetch(url + "blogs", {
      method: "GET",
      headers: header,
    })
      .then((res) => res.json())
      .then((res) => {
        res.blogs.map((blog) => (blog.key = blog._id));
        this.setState({
          blogs: res.blogs,
          load: true,
        });
      });
  };

  deleteRecord = (id) => {
    const url =
      window.location.hostname.includes("localhost") || window.location.hostname.includes("morseko")
        ? "http://localhost:8000/website/api/v1/"
        : "https://motajer.net/website/api/v1/";

    let header = {
      "x-app-token": "motajerWebsiteToken",
    };

    fetch(url + "blogs/" + id, {
      method: "DELETE",
      headers: header,
    }).then(() => {
      this.getData();
      message.success("blog has been deleted");
    });
  };

  cloneRecord = (id) => {
    const url =
      window.location.hostname.includes("localhost") || window.location.hostname.includes("morseko")
        ? "http://localhost:8000/website/api/v1/"
        : "https://motajer.net/website/api/v1/";

    let header = {
      "x-app-token": "motajerWebsiteToken",
    };

    fetch(url + "blogs/" + id + "/clone", {
      method: "POST",
      headers: header,
    }).then(() => {
      this.getData();
      message.success("blog has been cloned");
    });
  };

  activeToggle = (id) => {
    const url =
      window.location.hostname.includes("localhost") || window.location.hostname.includes("morseko")
        ? "http://localhost:8000/website/api/v1/"
        : "https://motajer.net/website/api/v1/";

    let header = {
      "x-app-token": "motajerWebsiteToken",
    };

    fetch(url + "blogs/" + id + "/active", {
      method: "PATCH",
      headers: header,
    }).then(() => {
      this.getData();
      message.success("blog has been toggled");
    });
  };

  render() {
    const { blogs, load } = this.state;

    const columns = [
      {
        title: "Cover",
        dataIndex: "cover",
        key: "cover",
        render: (text) => (
          <span>
            <img style={{ widh: 80, height: 80, objectFit: "contain" }} src={text ? website_url + text : "/img/logo.png"} />
          </span>
        ),
      },
      {
        title: "Title En",
        dataIndex: "title",
        key: "title",
        render: (text) => <span>{text?.["en"]}</span>,
      },
      {
        title: "Title Ar",
        dataIndex: "title",
        key: "title",
        render: (text) => <span>{text?.["ar"]}</span>,
      },
      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: (text, record) => (
          <Checkbox checked={text} onChange={() => this.activeToggle(record._id)}>
            active
          </Checkbox>
        ),
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/blogs/${record._id}/edit/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            <Popconfirm title="Are you sure clone this blog?" onConfirm={() => this.cloneRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-info">
                <MdCloud />
              </button>
            </Popconfirm>

            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-blogs admin-card pd">
          <div className="admin-title">
            <h6>blogs</h6>
            <Link to="/panel/blogs/add" className="btn btn-primary">
              New blog
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={blogs}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
