import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table } from "antd";
import { MdAccountBalance, MdDelete, MdEdit } from "react-icons/md";
import Http from "../../services/Http";
import ContentLoader from "../../components/loaders/ContentLoader";

class Records extends Component {
  state = {
    records: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("categories").then((res) => {
      res.data.records.map((category) => (category.key = category._id));
      this.setState({
        records: res.data.records,
        load: true,
      });
    });
  };

  deleteRecord = (id) => {
    Http.delete("categories/" + id)
      .then((res) => {
        this.getData();
        message.success("categories has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  render() {
    const { records, load } = this.state;

    const columns = [
      {
        title: "code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "name ar",
        dataIndex: "nameAr",
        key: "nameAr",
      },
      {
        title: "Categories",
        dataIndex: "categories",
        key: "categories",
        render: (text, record) => <span>{text?.length}</span>,
      },
      {
        title: "M color",
        dataIndex: "colors",
        key: "colors",
        render: (text) => (
          <span>
            <div
              className=""
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
                backgroundColor: text["main"],
                borderRadius: 300,
              }}
            ></div>
          </span>
        ),
      },
      {
        title: "S color",
        dataIndex: "colors",
        key: "colors",
        render: (text) => (
          <span>
            <div
              className=""
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
                backgroundColor: text["second"],
                borderRadius: 300,
                border: "1px solid #ccc",
              }}
            ></div>
          </span>
        ),
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/industries/${record._id}/edit/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>
            {/* <Link
              to={`/panel/categories/${record._id}/subs/`}
              className="btn btn-sm btn-info"
            >
              <MdAccountBalance />
            </Link> */}

            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>categories</h6>
            <Link to="/panel/categories/add" className="btn btn-primary">
              New category
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={records}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
