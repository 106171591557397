import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";

class UpdateRecord extends Component {
  state = {
    info: {},
    "name.en": "",
    "name.ar": "",
    mainColor: "",
    secondColor: "",
    code: 0,
    btnLoad: false,
  };

  async componentDidMount() {
    const { data } = await Http.get("subs/" + this.props.match.params.subId);
    this.setState({
      info: data.category,
      "name.en": data.category.name.en,
      "name.ar": data.category.name.ar,
    });
  }

  getData = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new category
    const payload = {
      name: {
        ar: this.state["name.ar"],
        en: this.state["name.en"],
      },
    };

    await Http.put("subs/" + this.props.match.params.subId, payload)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push(
          `/panel/categories/${this.props.match.params.id}/subs`
        );
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { btnLoad, info } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Update {info?.name?.en}</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link
              to={`/panel/categories/${this.props.match.params.id}/subs`}
              className="btn btn-warning"
            >
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-6">
                <Input
                  label="Name Ar"
                  placeholder="Name ar"
                  type="text"
                  value={this.state["name.ar"]}
                  required
                  onChange={this.getData}
                  name="name.ar"
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Name En"
                  placeholder="Name en"
                  type="text"
                  value={this.state["name.en"]}
                  required
                  onChange={this.getData}
                  name="name.en"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateRecord);
