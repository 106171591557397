import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../../components/forms/Input";
import { openNoti } from "../../../components/confirmation-popup/Notification";
import { Editor } from "@tinymce/tinymce-react";
import ReactFileReader from "react-file-reader";
import Http from "../../../services/Http";
class AddRecord extends Component {
  state = {
    "name.ar": "",
    "name.en": "",
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    const payload = {
      name: { ar: this.state["name.ar"], en: this.state["name.en"] },
    };

    const { data } = await Http.post("onDeskCategories", payload);
    openNoti(data?.message);
    this.setState({ btnLoad: false });
    this.props.history.push("/panel/docs");
  };

  render() {
    const { btnLoad } = this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-name">
          <h6>Add category</h6>
          <div className="admin-name-super2">
            <button type="submit" disabled={btnLoad} onClick={this.createRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/docs" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-6">
                <Input label="name ar" type="text" required onChange={this.getData} name="name.ar" />
              </div>
              <div className="col-md-6">
                <Input label="name en" type="text" required onChange={this.getData} name="name.en" />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
