import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";

class AddRecord extends Component {
  state = {
    "name.ar": "",
    "name.en": "",
    code: 0,
    price: {
      local: 0,
      global: 0,
    },
    errors: {},
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePrice = (event) => {
    let clone = this.state.price;

    clone[event.target.name] = event.target.value;
    this.setState({ price: clone });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new brand
    const feature = {
      name: { ar: this.state["name.ar"], en: this.state["name.en"] },
      code: Number(this.state.code),
      price: this.state.price,
    };

    await Http.post("features/", feature)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/features");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { errors, btnLoad } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Add Feature</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/features" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createBrand}>
            <div className="row align-items-center">
              <div className="col-md-6">
                <Input
                  label="Engish Name"
                  placeholder="Engish Name"
                  type="text"
                  required
                  error={errors["name.en"] ? errors["name.en"].message : false}
                  onChange={this.getData}
                  name="name.en"
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Arabic Name"
                  placeholder="Arabic Name"
                  type="text"
                  required
                  error={errors["name.ar"] ? errors["name.ar"].message : false}
                  onChange={this.getData}
                  name="name.ar"
                />
              </div>
              <div className="col-md-2">
                <Input
                  label="Code"
                  placeholder="1"
                  type="number"
                  required
                  error={errors["code"] ? errors["code"].message : false}
                  onChange={this.getData}
                  name="code"
                />
              </div>
              <div className="col-md-5">
                <Input
                  label="Price EGP"
                  type="number"
                  required
                  error={
                    errors["price.local"]
                      ? errors["price.local"].message
                      : false
                  }
                  onChange={this.handlePrice}
                  name="local"
                />
              </div>
              <div className="col-md-5">
                <Input
                  label="Price $"
                  type="number"
                  required
                  error={
                    errors["price.global"]
                      ? errors["price.global"].message
                      : false
                  }
                  onChange={this.handlePrice}
                  name="global"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
