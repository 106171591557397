import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import { openNoti } from "../../components/confirmation-popup/Notification";
import Input from "../../components/forms/Input";

class EditRecord extends Component {
  state = {
    id: 0,
    code: 0,
    data: {},
    "name.ar": "",
    "name.en": "",
    price: {
      local: 0,
      global: 0,
    },
    errors: {},
    load: false,
    btnLoad: false,
  };

  async componentDidMount() {
    const id = this.props.match.params.id;

    const { data: feature } = await Http.get("features/" + id);
    this.setState({
      id: id,
      data: feature,
      code: feature.feature.code,
      "name.en": feature.feature.name.en,
      "name.ar": feature.feature.name.ar,

      load: true,
    });

    if (feature.feature.price) this.state({ price: feature.feature.price });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  editModel = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });
    // Update Category
    const { id, code } = this.state;
    const feature = {
      name: {
        ar: this.state["name.ar"],
        en: this.state["name.en"],
      },
      price: this.state.price,
      code: Number(code),
    };

    await Http.put("features/" + id, feature)
      .then(async (result) => {
        openNoti("feature updated successfully");
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/features");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  handlePrice = (event) => {
    let clone = this.state.price;

    clone[event.target.name] = event.target.value;
    this.setState({ price: clone });
  };
  render() {
    const { load, errors, btnLoad } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Edit Feature</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.editModel}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/features" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        {load && (
          <div className="admin-form">
            <form onSubmit={this.editModel}>
              <div className="row">
                <div className="col-md-4">
                  <Input
                    label="Engish Name"
                    placeholder="Engish Name"
                    type="text"
                    required
                    defaultValue={this.state["name.en"]}
                    error={
                      errors["name.en"] ? errors["name.en"].message : false
                    }
                    onChange={this.getData}
                    name="name.en"
                  />
                </div>

                <div className="col-md-4">
                  <Input
                    label="Arabic Name"
                    placeholder="Arabic Name"
                    type="text"
                    required
                    defaultValue={this.state["name.ar"]}
                    error={
                      errors["name.ar"] ? errors["name.ar"].message : false
                    }
                    onChange={this.getData}
                    name="name.ar"
                  />
                </div>

                <div className="col-md-2">
                  <Input
                    label="Code"
                    placeholder="Code"
                    type="number"
                    required
                    value={this.state["code"]}
                    error={errors["code"] ? errors["code"].message : false}
                    onChange={this.getData}
                    name="code"
                  />
                </div>

                <div className="col-md-5">
                  <Input
                    label="Price EGP"
                    type="number"
                    value={this.state["price"].local}
                    required
                    error={
                      errors["price.local"]
                        ? errors["price.local"].message
                        : false
                    }
                    onChange={this.handlePrice}
                    name="local"
                  />
                </div>
                <div className="col-md-5">
                  <Input
                    label="Price $"
                    type="number"
                    required
                    value={this.state["price"].global}
                    error={
                      errors["price.global"]
                        ? errors["price.global"].message
                        : false
                    }
                    onChange={this.handlePrice}
                    name="global"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(EditRecord);
