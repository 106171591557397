import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, message, Popconfirm, Table, Modal } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import ContentLoader from "../../components/loaders/ContentLoader";
import Http from "../../services/Http";
import Input from "../../components/forms/Input";
import dayjs from "dayjs";

class LeadsPage extends Component {
  state = {
    records: [],
    load: false,
    record: null,
    modalOpen: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("getLeads").then((res) => {
      res.data.records.map((record) => (record.key = record._id));
      this.setState({
        records: res.data.records,
        load: true,
      });
    });
  };

  getUpdateData = (event) => {
    let clone = { ...this.state.record };
    clone[event.target.name] = event.target.value;
    this.setState({ record: clone });
  };

  deleteRecord = (id) => {
    Http.delete(`deleteLeads/${id}`).then((res) => {
      this.getData();
      message.success("lead has been deleted");
    });
  };

  handleEditLead = (record) => {
    console.log(record);
    console.log(this.state.modalOpen);
    this.setState({ record: record, modalOpen: true });
  };

  handleUpdate = () => {
    Http.put(`updateLeads/${this.state.record._id}`, this.state.record).then((res) => {
      this.getData();
      this.setState({ modalOpen: false });
      message.success("lead has been updated");
    });
  };

  render() {
    const { records, load } = this.state;

    const columns = [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => <span>{text ? dayjs(text).format("DD-MM-YYYY hh:mm a") : "-"}</span>,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text) => <span>{text}</span>,
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Button onClick={() => this.handleEditLead(record)} className="btn btn-sm btn-warning">
              <MdEdit />
            </Button>

            <Popconfirm title="Are you sure delete this lead?" onConfirm={() => this.deleteRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-blogs admin-card pd">
          <div className="admin-title">
            <h6>Leads</h6>
            {/* <Link to="/panel/leads/add" className="btn btn-primary">
              New lead
            </Link> */}
          </div>

          <Modal
            title="Update Lead"
            visible={this.state.modalOpen}
            onOk={() => {
              this.handleUpdate();
            }}
            onCancel={() => {
              this.setState({ modalOpen: false, record: null });
            }}
          >
            <Input label="Full Name" type="text" value={this.state.record?.name} onChange={this.getUpdateData} name="name" />
            <Input label="Company Name" type="text" value={this.state.record?.companyName} onChange={this.getUpdateData} name="companyName" />
            <Input label="Email" type="email" value={this.state.record?.email} onChange={this.getUpdateData} name="email" />
            <Input label="Phone" type="tel" value={this.state.record?.phone} onChange={this.getUpdateData} name="phone" />
            <Input label="Country" disabled type="tel" value={this.state.record?.country} onChange={this.getUpdateData} name="country" />
            <Input label="City" disabled type="tel" value={this.state.record?.city} onChange={this.getUpdateData} name="city" />
            <Input label="Message" type="textarea" value={this.state.record?.description} onChange={this.getUpdateData} name="description" />
          </Modal>

          {load && (
            <Table
              columns={columns}
              dataSource={records}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default LeadsPage;
