import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import ReactFileReader from "react-file-reader";

class DuplicateRecord extends Component {
  state = {
    adminName: "",
    marketName: "",
    industry: "",
    industries: [],
    phone: 0,
    email: 0,
    logoImage: "",
    logo: "",
    password: "",
    subdomain: "",
    domain: "",
    plan: "",
    load: true,
    plans: [],
    errors: {},
    btnLoad: false,
    old: {},
  };

  async componentDidMount() {
    Promise.all([await Http.get("plans"), await Http.get("customers/" + this.props.match.params.id), await Http.get("industries")]).then((res) => {
      this.setState({
        plans: res[0].data.plans,
        old: res[1].data.customer,
        industries: res[2].data.industries,
        load: false,
      });
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ logo: files[0], logoImage: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ logo: false, logoImage: false });

  createRecord = async (event) => {
    const { adminName, marketName, phone, email, password, subdomain, domain, plan, logo, industry, old } = this.state;

    event.preventDefault();

    this.setState({ btnLoad: true });

    // Create new brand
    const customer = {
      adminName,
      marketName,
      phone,
      email,
      password,
      subdomain,
      domain,
      plan,
      cloneStoreStaticName: old.staticName,
    };

    if (industry) customer.industry = industry;

    await Http.post("customers/duplicate", customer)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/customers");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { errors, old, btnLoad, load, adminName, marketName, phone, email, subdomain, domain, plan, logoImage, industry, industries } = this.state;
    return (
      <div className="all-industries admin-card pd">
        <div className="admin-title">
          <h6>
            Duplicate <span className="main-color"> {old.marketName}</span>
          </h6>
          <div className="admin-title-super2">
            <button type="submit" disabled={btnLoad} onClick={this.createRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Duplicate.....
                </span>
              ) : (
                "Duplicate"
              )}
            </button>

            <Link to="/panel/customers" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createBrand}>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <ReactFileReader handleFiles={this.handleImage} fileTypes={[".jpg", ".png"]}>
                    <div className="form-group">
                      <div className="image-preview single">
                        <img src={logoImage ? Http.front_url + logoImage : "/img/logo.png"} alt="motajer" title="image" />
                      </div>
                    </div>
                  </ReactFileReader>
                  {logoImage && (
                    <button className="remove-image" onClick={this.clearLogo}>
                      <DeleteOutlined />
                    </button>
                  )}
                </div>

                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        label="Industry"
                        type="getOption"
                        defaultValue={industry}
                        options={industries}
                        fOption="select industry"
                        error={errors["industry"] ? errors["industry"].message : false}
                        onChange={this.getData}
                        name="industry"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Market name"
                        placeholder="Motajer"
                        type="text"
                        required
                        error={errors["marketName"] ? errors["marketName"].message : false}
                        onChange={this.getData}
                        name="marketName"
                        defaultValue={marketName}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Admin Name"
                        placeholder="Admin Name"
                        type="text"
                        required
                        error={errors["adminName"] ? errors["adminName"].message : false}
                        onChange={this.getData}
                        name="adminName"
                        defaultValue={adminName}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Email"
                        placeholder="email"
                        type="email"
                        required
                        error={errors["email"] ? errors["email"].message : false}
                        onChange={this.getData}
                        name="email"
                        defaultValue={email}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Phone"
                        placeholder="phone"
                        type="tel"
                        required
                        error={errors["phone"] ? errors["phone"].message : false}
                        onChange={this.getData}
                        name="phone"
                        defaultValue={phone}
                      />
                    </div>
                    {/* <div className="col-md-3">
                      <Input
                        label="Subdomain"
                        placeholder="subdomain"
                        type="text"
                        required
                        error={errors["subdomain"] ? errors["subdomain"].message : false}
                        onChange={this.getData}
                        name="subdomain"
                        defaultValue={subdomain}
                      />
                    </div> */}
                    <div className="col-md-3">
                      <Input
                        label="Domain"
                        placeholder="domain"
                        type="text"
                        error={errors["domain"] ? errors["domain"].message : false}
                        onChange={this.getData}
                        name="domain"
                        defaultValue={domain}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Password"
                        placeholder="password"
                        type="password"
                        required
                        error={errors["password"] ? errors["password"].message : false}
                        onChange={this.getData}
                        name="password"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Plan"
                        type="select"
                        fOption="Select plan"
                        options={this.state.plans}
                        required
                        error={errors["plan"] ? errors["plan"].message : false}
                        onChange={this.getData}
                        name="plan"
                        value={plan}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(DuplicateRecord);
