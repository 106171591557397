import { Button, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import Http from "../../services/Http";
import { Option } from "antd/lib/mentions";
import { Link } from "react-router-dom";

export default function CreateSubscriptions() {
  const [input, setInput] = useState();
  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  console.log(input);
  const handleChange = (value) => {
    setInput1(` ${value}`);
  };
  const handleChangePlane = (value) => {
    setInput2(` ${value}`);
  };
  useEffect(() => {
    getData1();
  }, []);
  useEffect(() => {
    getData();
  }, []);
  const getData1 = () => {
    setLoad(true);
    Http.get("/allCustomers").then((res) => {
      setCustomers(res?.data);
      setLoad(false);
    });
  };
  const runSubscribe = async (e) => {
    e.preventDefault();
    setLoad(true);
    Http.post(`/createSubscription`, { monthsCount: input, customerId: input1, planId: input2 })
      .then(() => {
        message.success("The operation was successful");
        setLoad(false);
      })
      .catch((error) => {
        message.error("The operation failed");
        console.error("Error:", error);
      });
  };

  const getData = () => {
    setLoad(true);
    Http.get("/plans").then((res) => {
      console.log(res?.data);
      setData(res?.data?.plans);
      //   window.open(data?.message?.checkoutURL?.data, "_blank");
      //   console.log("ddddddddddd", data);
      setLoad(false);
    });
  };
  const onFinish = (values) => {
    Http.post(`/createSubscription`, values)
      .then(() => {
        message.success("The operation was successful");

        setLoad(false);
      })
      .catch((error) => {
        message.error("The operation failed");
        console.error("Error:", error);
      });
  };

  return (
    <div className="all-categories admin-card pd">
      <div className="admin-title">
        <h6>Subscription</h6>
        <div className="admin-title-super2">
          <Link to="/panel/subscription" className="btn btn-warning">
            Cancel
          </Link>
        </div>
      </div>
      <Form className=" relative grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3  gap-3" name="basic" onFinish={onFinish}>
        <Form.Item
          label="months Count"
          name="monthsCount"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="customer"
          name="customerId"
          rules={[
            {
              required: true,
              message: "Please input your customer!",
            },
          ]}
        >
          <Select onChange={handleChange}>
            {customers?.map((i) => (
              <Option key={i._id} value={i._id}>
                {i.adminName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="plan"
          name="planId"
          rules={[
            {
              required: true,
              message: "Please input your plan!",
            },
          ]}
        >
          <Select onChange={handleChangePlane}>
            {data?.map((i) => (
              <Option key={i._id} value={i._id}>
                {i.name?.ar}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button className=" !absolute -bottom-5 right-[40%] sm:right-[50%] sm:left-[45%]  sm:w-[150px]   " type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
