import axios from "axios";

function getPath() {
  if (window.location.href.includes("ahmedfarouqdev") || window.location.href.includes("motajerbot.com"))
    return "https://test-system-services.motajerbot.com/system/api/v1/";
  else if (window.location.href.includes("motajer.net")) return "https://system-services.motajer.net/system/api/v1/";
  else return "http://localhost:8084/system/api/v1/";
}

function systemPath() {
  if (window.location.href.includes("ahmedfarouqdev") || window.location.href.includes("motajerbot.com"))
    return "https://test-system-services.motajerbot.com/";
  else if (window.location.href.includes("motajer.net")) return "https://system-services.motajer.net/";
  else return "http://localhost:8084/";
}
// Make some default configuration
axios.defaults.baseURL = getPath();

axios.defaults.headers.common["x-app-token"] = "motajerSystemToken";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("super_token")}`;
axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

let front_url = systemPath();

// Export reuest services
export default {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  patch: axios.patch,
  front_url,
};
