import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table, Checkbox } from "antd";
import { MdCloud, MdDelete, MdEdit } from "react-icons/md";
import ContentLoader from "../../components/loaders/ContentLoader";
import { website_url } from "../../helpers/data";
import Http from "../../services/Http";

class Records extends Component {
  state = {
    data: [],
    categories: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { data } = await Http.get("onDeskBlogs");
    const categoriesRes = await Http.get("onDeskCategories");

    this.setState({
      data: data?.record,
      categories: categoriesRes.data?.record,
      load: true,
    });
  };

  deleteRecord = async (url, id) => {
    await Http.delete(`${url}/${id}`);
    message.success("record has been deleted");
    this.getData();
  };

  render() {
    const { data, load, categories } = this.state;

    const departmentcolumns = [
      {
        title: "Name En",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["en"]}</span>,
      },
      {
        title: "Name Ar",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["ar"]}</span>,
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/doc-department/${record._id}/update/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            <Popconfirm
              title="Are you sure delete this?"
              onConfirm={() => this.deleteRecord("onDeskCategories", record._id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    const docscolumns = [
      {
        title: "Name En",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["en"]}</span>,
      },
      {
        title: "Name Ar",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["ar"]}</span>,
      },
      {
        title: "department",
        dataIndex: "category",
        key: "category",
        render: (text) => <span>{text?.name?.en}</span>,
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/docs/${record._id}/update/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteRecord("onDeskBlogs", record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-blogs admin-card pd">
          <div className="admin-title">
            <h6>Docs Departments</h6>
            <Link to="/panel/doc-department/add" className="btn btn-primary">
              New department
            </Link>
          </div>

          {load && (
            <Table
              columns={departmentcolumns}
              dataSource={categories ? categories : []}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>

        <div className="all-blogs admin-card pd">
          <div className="admin-title">
            <h6>Docs</h6>
            <Link to="/panel/docs/add" className="btn btn-primary">
              New Doc
            </Link>
          </div>

          {load && (
            <Table
              columns={docscolumns}
              dataSource={data ? data : []}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
