/**
 * Main App
 */
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DevelopRoute from "./services/DevelopRoute";
import ProtectRoute from "./services/ProtectRoute";
import OwnerRoute from "./services/OwnerRoute";
import ReactDOM from "react-dom";
import "./pages/style.scss";

// admin layout
import AdminSidebar from "./components/layouts/adminSidebar/Sidebar";

// pages
import Statistics from "./pages/home";

import Features from "./pages/features";
import EditFeature from "./pages/features/Edit";
import AddFeature from "./pages/features/Add";

import Plans from "./pages/plans";
import EditPlan from "./pages/plans/Edit";
import AddPlan from "./pages/plans/Add";

import Blogs from "./pages/blogs";
import EditBlog from "./pages/blogs/Edit";
import AddBlog from "./pages/blogs/Add";

import Docs from "./pages/docs";

import AddDepartment from "./pages/docs/departments/Add";
import UpdateDepartment from "./pages/docs/departments/Edit";

import AddDoc from "./pages/docs/docs/Add";
import UpdateDoc from "./pages/docs/docs/Edit";

import Works from "./pages/works";
import EditWork from "./pages/works/Edit";
import AddWork from "./pages/works/Add";

import Products from "./pages/products";
import EditProduct from "./pages/products/Edit";
import AddProduct from "./pages/products/Add";

import Stuff from "./pages/stuff";
import EditStuff from "./pages/stuff/Edit";
import AddStuff from "./pages/stuff/Add";

import Industries from "./pages/industries";
import EditIndustry from "./pages/industries/Edit";
import AddIndustry from "./pages/industries/Add";

import Categories from "./pages/categories";
import EditCategory from "./pages/categories/Edit";
import AddCategory from "./pages/categories/Add";

import Subs from "./pages/subs";
import EditSub from "./pages/subs/update";
import AddSub from "./pages/subs/add";

import Customers from "./pages/customers";
import EditCustomer from "./pages/customers/Edit";
import DuplicateCustomer from "./pages/customers/Duplicate";
import AddCustomer from "./pages/customers/Add";

import Components from "./pages/design/components";
import UpdateComponent from "./pages/design/components/Edit";
import AddComponent from "./pages/design/components/Add";

import Blocks from "./pages/design/blocks";
import UpdateBlock from "./pages/design/blocks/Edit";
import AddBlock from "./pages/design/blocks/Add";
//  comment
import Templates from "./pages/design/templates";
import UpdateTemplate from "./pages/design/templates/Edit";
import AddTemplate from "./pages/design/templates/Add";

import Bills from "./pages/bills";
import EditBill from "./pages/bills/Edit";
import AddBill from "./pages/bills/Add";

import Login from "./pages/login";

import Movements from "./pages/storage";
import AddMovement from "./pages/storage/Add";
import LeadsPage from "./pages/leads";
import Affiliate from "./pages/Affiliate/Affiliate";
import AddAffiliate from "./pages/Affiliate/AddAffiliate";
import EditAffiliate from "./pages/Affiliate/EditAffiliate";
import AllSubscription from "./pages/subscription/AllSubscription";
import CreateSubscriptions from "./pages/subscription/CreateSubscriptions";
import EiditSubscription from "./pages/subscription/EiditSubscription";

class App extends React.Component {
  componentDidMount() {
    if (window.location.pathname.includes("panel/login")) {
      ReactDOM.render(<Login />, document.getElementById("root"));
    }
  }
  render() {
    return (
      <div className="admin-layout" id="admin-layout">
        <AdminSidebar />

        <div className="admin-wrapper">
          <div className="container-fluid">
            <Switch>
              <Redirect exact path="/panel" to="/panel/statics" component={Statistics} />

              <OwnerRoute exact path="/panel/statics" component={Statistics} />

              {/* Features  */}
              <DevelopRoute exact path="/panel/features/add" component={AddFeature} />
              <DevelopRoute exact path="/panel/features/:id/edit" component={EditFeature} />
              <OwnerRoute exact path="/panel/features" component={Features} />
              {/* Plans  */}
              <DevelopRoute exact path="/panel/plans/add" component={AddPlan} />
              <DevelopRoute exact path="/panel/plans/:id/edit" component={EditPlan} />
              <OwnerRoute exact path="/panel/plans" component={Plans} />
              {/* Docs  */}
              <ProtectRoute exact path="/panel/docs" component={Docs} />
              <ProtectRoute exact path="/panel/doc-department/add" component={AddDepartment} />
              <ProtectRoute exact path="/panel/doc-department/:id/update" component={UpdateDepartment} />

              <ProtectRoute exact path="/panel/docs/add" component={AddDoc} />
              <ProtectRoute exact path="/panel/docs/:id/update" component={UpdateDoc} />
              {/* Blogs  */}
              <ProtectRoute exact path="/panel/blogs/add" component={AddBlog} />
              <ProtectRoute exact path="/panel/blogs/:id/edit" component={EditBlog} />
              <ProtectRoute exact path="/panel/blogs" component={Blogs} />
              {/* works  */}
              <ProtectRoute exact path="/panel/works/add" component={AddWork} />
              <ProtectRoute exact path="/panel/works/:id/edit" component={EditWork} />
              <ProtectRoute exact path="/panel/works" component={Works} />
              {/* Products  */}
              <ProtectRoute exact path="/panel/products/add" component={AddProduct} />
              <ProtectRoute exact path="/panel/products/:id/edit" component={EditProduct} />
              <ProtectRoute exact path="/panel/products" component={Products} />
              {/* Products  */}
              {/* <ProtectRoute exact path="/panel/leads/add" component={AddProduct} />
              <ProtectRoute exact path="/panel/leads/:id/edit" component={EditProduct} /> */}
              <ProtectRoute exact path="/panel/leads" component={LeadsPage} />
              {/* Stuff  */}
              <OwnerRoute exact path="/panel/stuff/add" component={AddStuff} />
              <OwnerRoute exact path="/panel/stuff/:id/edit" component={EditStuff} />
              <OwnerRoute exact path="/panel/stuff" component={Stuff} />
              {/* industries  */}
              <ProtectRoute exact path="/panel/industries/add" component={AddIndustry} />
              <ProtectRoute exact path="/panel/industries/:id/edit" component={EditIndustry} />
              <ProtectRoute exact path="/panel/industries" component={Industries} />
              {/* Categories  */}
              <ProtectRoute exact path="/panel/categories/add" component={AddCategory} />
              <ProtectRoute exact path="/panel/categories/:id/edit" component={EditCategory} />
              <ProtectRoute exact path="/panel/categories" component={Categories} />
              {/* Subs  */}
              <ProtectRoute exact path="/panel/categories/:id/subs/add" component={AddSub} />
              <ProtectRoute exact path="/panel/categories/:id/subs/:subId/edit" component={EditSub} />
              <ProtectRoute exact path="/panel/categories/:id/subs" component={Subs} />
              {/* Components  */}
              <ProtectRoute exact path="/panel/blocks/add" component={AddBlock} />
              <ProtectRoute exact path="/panel/blocks/:id/edit" component={UpdateBlock} />

              <ProtectRoute exact path="/panel/blocks" component={Blocks} />
              {/* Templates  */}
              <ProtectRoute exact path="/panel/templates/add" component={AddTemplate} />
              <ProtectRoute exact path="/panel/templates/:id/edit" component={UpdateTemplate} />

              <ProtectRoute exact path="/panel/templates" component={Templates} />
              {/* Customers  */}
              <OwnerRoute exact path="/panel/customers/add" component={AddCustomer} />
              <OwnerRoute exact path="/panel/customers/:id/edit" component={EditCustomer} />
              <OwnerRoute exact path="/panel/customers/:id/duplicate" component={DuplicateCustomer} />
              <OwnerRoute exact path="/panel/customers" component={Customers} />
              {/* Bills  */}
              <OwnerRoute exact path="/panel/bills/add" component={AddBill} />
              <OwnerRoute exact path="/panel/bills/:id/edit" component={EditBill} />
              <OwnerRoute exact path="/panel/bills" component={Bills} />
              {/* Movements  */}
              <OwnerRoute exact path="/panel/movements/add" component={AddMovement} />
              <OwnerRoute exact path="/panel/movements" component={Movements} />
              <OwnerRoute exact path="/panel/affiliate" component={Affiliate} />
              <OwnerRoute exact path="/panel/affiliate/add" component={AddAffiliate} />
              <OwnerRoute exact path="/panel/affiliate/:id/edit" component={EditAffiliate} />
              <OwnerRoute exact path="/panel/subscription" component={AllSubscription} />
              <OwnerRoute exact path="/panel/create_subscription" component={CreateSubscriptions} />
              <OwnerRoute exact path="/panel/subscription/:id/edit" component={EiditSubscription} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
