import React from "react";
import { Route } from "react-router-dom";
import { userRole } from "./user";
import { checkToken } from "../helpers/checkToken";

const DevelopRoute = ({ path, component: Component }) => {
  const GoToPage = () => {
    // window.location.replace("/panel/login");
  };

  return (
    <Route
      path={path}
      render={() => {
        // if (userRole() != 2 && checkToken()) {
        //   return <GoToPage />;
        // }
        return <Component />;
      }}
    />
  );
};

export default DevelopRoute;
