import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import Http from "../../services/Http";
import ContentLoader from "../../components/loaders/ContentLoader";

class Records extends Component {
  state = {
    features: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("features").then((res) => {
      res.data.features.map((feature) => (feature.key = feature._id));

      this.setState({
        features: res.data.features.sort((a, b) => a.code - b.code),
        load: true,
      });
    });
  };

  deleteRecord = (id) => {
    Http.delete("features/" + id)
      .then((res) => {
        this.getData();
        message.success("feature has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  render() {
    const { features, load } = this.state;

    const columns = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Name En",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["en"]}</span>,
      },
      {
        title: "Name Ar",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["ar"]}</span>,
      },
      {
        title: "Price EGP",
        dataIndex: "price",
        key: "price",
        render: (text) => <span>{text?.["local"]}</span>,
      },
      {
        title: "Price $",
        dataIndex: "price.global",
        key: "price.global",
        render: (text) => <span>{text?.["global"]}</span>,
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/panel/features/${record._id}/edit/`}
              className="btn btn-sm btn-warning"
            >
              <MdEdit />
            </Link>

            <Popconfirm
              title="Are you sure delete this?"
              onConfirm={() => this.deleteRecord(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Features</h6>
            <Link to="/panel/features/add" className="btn btn-primary">
              New feature
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={features}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
