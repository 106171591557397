import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { MdEdit } from "react-icons/md";
import ContentLoader from "../../../components/loaders/ContentLoader";
import Http from "../../../services/Http";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

class Records extends Component {
  state = {
    records: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("blocks").then((res) => {
      res.data.data.map((c) => (c.key = c._id));
      this.setState({
        records: res.data.data,
        load: true,
      });
    });
  };

  //   deleteRecord = (id) => {
  //     const url =
  //       window.location.hostname.includes("localhost") ||
  //       window.location.hostname.includes("morseko")
  //         ? "http://localhost:8000/system/api/v1/"
  //         : "https://motajer.net/website/api/v1/";

  //     let header = {
  //       "x-app-token": "motajerWebsiteToken",
  //     };

  //     fetch(url + "blogs/" + id, {
  //       method: "DELTE",
  //       headers: header,
  //     }).then(() => {
  //       this.getData();
  //       message.success("blog has been deleted");
  //     });
  //   };

  render() {
    const { records, load } = this.state;

    const columns = [
      {
        title: "code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "type",
        dataIndex: "type",
        key: "type",
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/blocks/${record._id}/edit/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            {/* <Popconfirm
              title="Are you sure delete this?"
              onConfirm={() => this.deleteRecord(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm> */}
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-blogs admin-card pd">
          <div className="admin-title">
            <h6>Blocks</h6>
            <Link to="/panel/blocks/add" className="btn btn-primary">
              New block
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={records}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
