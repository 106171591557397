import React from "react";

const Input = ({ label, type, className, required, name, fOption, options, value, onChange, placeholder, defaultValue, error, disabled }) => {
  return (
    <div className="form-group">
      <div className="label-header">
        <label className="custom-label" htmlFor={name}>
          {label} {required ? <span className="required required-star">*</span> : ""}
        </label>
        {error && <small className="required">{error}</small>}
      </div>
      {type === "select2" && (
        <select
          required={required}
          name={name}
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options
              .filter((c) => c.sub_categories.length > 0)
              .map((parent) => (
                <optgroup key={parent._id} label={parent.name.en}>
                  {parent.sub_categories.map((sub) => (
                    <option value={sub._id} key={sub._id}>
                      {sub.name.en}
                    </option>
                  ))}
                </optgroup>
              ))}
        </select>
      )}
      {type === "normal-select" && (
        <select
          required={required}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options.map((opt, i) => (
              <option value={opt} key={i}>
                {opt}
              </option>
            ))}
        </select>
      )}
      {type === "select" && (
        <select
          required={required}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options.map((opt) => (
              <option value={opt._id} key={opt._id}>
                {opt.name.en}
              </option>
            ))}
        </select>
      )}
      {type === "getOption" && (
        <select
          required={required}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options.map((opt) => (
              <option value={opt._id} key={opt._id}>
                {opt.name}
              </option>
            ))}
        </select>
      )}
      {type === "select3" && (
        <select
          required={required}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
        </select>
      )}
      {(type === "text" || type === "number" || type === "tel" || type === "password" || type === "color" || type === "email") && (
        <input
          type={type}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          className={`form-control ${className ? className : ""}`}
          required={required}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {type === "textarea" && (
        <textarea
          type={type}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          className={`form-control ${className ? className : ""}`}
          required={required}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default Input;
