import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table, Switch } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import Http from "../../services/Http";
import ContentLoader from "../../components/loaders/ContentLoader";

class Records extends Component {
  state = {
    plans: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("plans").then((res) => {
      res.data.plans.map((plan) => (plan.key = plan._id));
      this.setState({
        plans: res.data.plans,
        load: true,
      });
    });
  };

  toggleActive = (value) => {
    Http.post("plans/" + value + "/toggleActive")
      .then((res) => {
        message.success("plan has been toggle activate");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  deleteRecord = (id) => {
    Http.delete("plans/" + id)
      .then((res) => {
        this.getData();
        message.success("plan has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  render() {
    const { plans, load } = this.state;

    const columns = [
      {
        title: "code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Name En",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["en"]}</span>,
      },
      {
        title: "Name Ar",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["ar"]}</span>,
      },
      {
        title: "products",
        dataIndex: "products",
        key: "products",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Price M",
        dataIndex: "price",
        key: "price",
        render: (text) => <span>{text?.["egp"]?.["monthly"]}</span>,
      },
      {
        title: "Price Y",
        dataIndex: "price",
        key: "price",
        render: (text) => <span>{text?.["egp"]?.["yearly"]}</span>,
      },
      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: (text, record) => (
          <span>
            <Switch defaultChecked={text} onChange={(value) => this.toggleActive(record._id, value)} />
          </span>
        ),
      },
      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/plans/${record._id}/edit/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>plans</h6>
            <Link to="/panel/plans/add" className="btn btn-primary">
              New plan
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={plans}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
