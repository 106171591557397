import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined, LoginOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";

class AddRecord extends Component {
  state = {
    name: "",
    nameAr: "",
    mainColor: "",
    secondColor: "",
    categories: [],
    code: 0,
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addChildren = () => {
    this.setState({
      categories: [...this.state.categories, { nameEn: "", nameAr: "" }],
    });
  };

  changeChild = (index, event) => {
    let clone = [...this.state.categories];

    if (["nameAr", "nameEn"].includes(event.target.name)) {
      if (clone?.[index]?.["name"]) clone[index]["name"][event.target.name === "nameAr" ? "ar" : "en"] = event.target.value;
      else clone[index] = { name: { [event.target.name === "nameAr" ? "ar" : "en"]: event.target.value } };
    } else clone[index][event.target.name] = event.target.value;

    this.setState({
      categories: clone,
    });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new payload
    const payload = {
      name: this.state.name,
      nameAr: this.state.nameAr,
      code: this.state.code,
      colors: {
        main: this.state.mainColor,
        second: this.state.secondColor,
      },
      categories: this.state.categories,
    };

    await Http.post("industries", payload)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/industries");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { btnLoad } = this.state;
    return (
      <div className="">
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Add industry</h6>
            <div className="admin-title-super2">
              <button type="submit" disabled={btnLoad} onClick={this.createRecord} className="btn btn-primary form-button">
                {btnLoad ? (
                  <span>
                    <LoadingOutlined /> Create
                  </span>
                ) : (
                  "Create"
                )}
              </button>

              <Link to="/panel/industries" className="btn btn-warning">
                Cancel
              </Link>
            </div>
          </div>

          <div className="admin-form">
            <form onSubmit={this.createBrand}>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <Input label="Name" placeholder="Name" type="text" required onChange={this.getData} name="name" />
                </div>
                <div className="col-md-6">
                  <Input label="Name ar" placeholder="Name" type="text" required onChange={this.getData} name="nameAr" />
                </div>
                <div className="col-md-4">
                  <Input label="Code" placeholder="Code" type="text" required onChange={this.getData} name="code" />
                </div>
                <div className="col-md-4">
                  <Input label="Main color" type="color" required onChange={this.getData} name="mainColor" />
                </div>
                <div className="col-md-4">
                  <Input label="Second color" type="color" required onChange={this.getData} name="secondColor" />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="admin-card pd">
          <div className="admin-title">
            <h6>Categories</h6>
            <button onClick={this.addChildren} className="btn btn-sm btn-primary">
              +
            </button>
          </div>

          {this.state.categories.map((child, index) => (
            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Name"
                  placeholder="Name"
                  type="text"
                  required
                  onChange={(event) => this.changeChild(index, event)}
                  name="nameEn"
                  value={child["nameEn"]}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Name ar"
                  placeholder="Name"
                  type="text"
                  required
                  onChange={(event) => this.changeChild(index, event)}
                  name="nameAr"
                  value={child["nameAr"]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
