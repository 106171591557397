import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import { Editor } from "@tinymce/tinymce-react";
import ReactFileReader from "react-file-reader";
import { system_url, website_url } from "../../helpers/data";
import Http from "../../services/Http";
import { Select } from "antd";

class EditRecord extends Component {
  state = {
    "name.ar": "",
    "name.en": "",
    "description.ar": "",
    "description.en": "",
    "content.ar": "",
    "content.en": "",
    cover: false,
    preview: false,
    errors: {},
    categories: [],
    industries: [],
    industry: null,
    category: null,
    btnLoad: false,
  };

  async componentDidMount() {
    Http.get(`products/${this.props.match.params.id}`).then((result) => {
      this.setState({
        "name.ar": result.data.product.name?.ar,
        "name.en": result.data.product.name?.en,
        "description.ar": result.data.product.description.ar,
        "description.en": result.data.product.description.en,
        "shortDesc.ar": result.data.product.shortDesc.ar,
        "shortDesc.en": result.data.product.shortDesc.en,
        userPrice: result.data.product.price.user,
        adminPrice: result.data.product.price.admin,
        quantity: result.data.product.quantity,
        sku: result.data.product.sku,
        industry: result.data.product.industry?._id,
        category: result.data.product.category?._id,
        preview: result.data.product.gallaries?.[0] ? system_url + result.data.product.gallaries[0] : false,
      });
    });

    const { data } = await Http.get("industries");

    data.industries.map((item) => {
      item.title = item.name;
      item.value = item._id;
    });

    data.industries[0].categories.map((item) => (item.title = item.name.en));

    this.setState({ industries: data.industries });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getCategory = (value) => {
    this.setState({ category: value });
  };

  getIndustry = (value) => {
    let target = this.state.industries.find((industry) => industry._id == value);
    target.categories.map((item) => (item.title = item.name.en));
    this.setState({ categories: this.state.industries.find((industry) => industry._id == value).categories });
    this.setState({ industry: value });
  };

  handleImage = (files) => {
    this.setState({ cover: files[0], preview: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ cover: false, preview: false });

  updateRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    const record = {
      name: { ar: this.state["name.ar"], en: this.state["name.en"] },
      description: {
        ar: this.state["description.ar"],
        en: this.state["description.en"],
      },
      shortDesc: {
        ar: this.state["shortDesc.ar"],
        en: this.state["shortDesc.en"],
      },
      price: {
        user: this.state["userPrice"],
        admin: this.state["adminPrice"],
      },
      quantity: this.state["quantity"],
      category: this.state["category"],
      industry: this.state["industry"],
      sku: this.state["sku"],
      content: { ar: this.state["content.ar"], en: this.state["content.en"] },
    };

    Http.put(`products/${this.props.match.params.id}`, record)
      .then(async (res) => {
        if (this.state.cover) {
          let payload = new FormData();
          payload.append("gallaries", this.state.cover);
          await Http.patch(`products/${this.props.match.params.id}/gallaries`, payload);
        }
        openNoti(res.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/products");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const { errors, btnLoad, preview, categories, industries } = this.state;
    const { Option } = Select;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-title">
          <h6>Update item</h6>
          <div className="admin-title-super2">
            <button type="submit" disabled={btnLoad} onClick={this.updateRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/products" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <ReactFileReader handleFiles={this.handleImage} fileTypes={[".jpg", ".png", "jpeg"]}>
                  <div className="form-group">
                    <div className="image-preview single-blog">
                      <img src={preview.length > 0 ? preview : "/img/logo.png"} alt="motajer" title="image" />
                    </div>
                  </div>
                </ReactFileReader>
                {preview && (
                  <button className="remove-image" onClick={this.clearLogo}>
                    <DeleteOutlined />
                  </button>
                )}
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-6">
                <Input
                  label="name ar"
                  type="text"
                  required
                  error={errors["name.ar"] ? errors["name.ar"].message : false}
                  onChange={this.getData}
                  name="name.ar"
                  value={this.state["name.ar"]}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="name en"
                  type="text"
                  required
                  error={errors["name.en"] ? errors["name.en"].message : false}
                  onChange={this.getData}
                  name="name.en"
                  value={this.state["name.en"]}
                />
              </div>
              <div className="col-md-6 mb-2">
                <label>Industry</label>
                <Select
                  style={{ width: "100%" }}
                  value={this.state["industry"]}
                  showSearch={true}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  onChange={(value) => this.getIndustry(value)}
                >
                  {industries.map((item) => (
                    <Option value={item._id}>{item.name}</Option>
                  ))}
                </Select>
              </div>
              <div className="col-md-6 mb-2">
                <label>Category</label>
                <Select
                  style={{ width: "100%" }}
                  value={this.state["category"]}
                  showSearch={true}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  onChange={(value) => this.getCategory(value)}
                >
                  {categories.map((item) => (
                    <Option value={item._id}>{item.name.en}</Option>
                  ))}
                </Select>
              </div>
              <div className="col-md-3">
                <Input
                  label="SKU"
                  type="text"
                  required
                  error={errors["sku"] ? errors["sku"].message : false}
                  onChange={this.getData}
                  name="sku"
                  value={this.state["sku"]}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="Quantity"
                  type="number"
                  required
                  error={errors["quantity"] ? errors["quantity"].message : false}
                  onChange={this.getData}
                  name="quantity"
                  value={this.state["quantity"]}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="user price"
                  type="number"
                  required
                  error={errors["userPrice"] ? errors["userPrice"].message : false}
                  onChange={this.getData}
                  name="userPrice"
                  value={this.state["userPrice"]}
                />
              </div>

              <div className="col-md-3">
                <Input
                  label="admin price"
                  type="number"
                  required
                  error={errors["adminPrice"] ? errors["adminPrice"].message : false}
                  onChange={this.getData}
                  name="adminPrice"
                  value={this.state["adminPrice"]}
                />
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Description ar ( SEO )</label>
                  <textarea onChange={this.getData} className="form-control" name="shortDesc.ar" value={this.state["shortDesc.ar"]}></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Description en ( SEO )</label>
                  <textarea onChange={this.getData} className="form-control" name="shortDesc.en" value={this.state["shortDesc.en"]}></textarea>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Description Ar</label>
                  <Editor
                    initialValue={this.state["description.ar"]}
                    apiKey="lase8gh50s3eano5gluqxsg4lghx4d8gshv0xw0gn4sejanq"
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists emoticons directionality link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor  | \
             alignleft aligncenter alignright alignjustify ltr rtl emoticons | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(editor, content) => {
                      this.setState({ "description.ar": editor });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Description En</label>
                  <Editor
                    initialValue={this.state["description.en"]}
                    apiKey="lase8gh50s3eano5gluqxsg4lghx4d8gshv0xw0gn4sejanq"
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists emoticons directionality link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor  | \
             alignleft aligncenter alignright alignjustify ltr rtl emoticons | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(editor, content) => {
                      this.setState({ "description.en": editor });
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EditRecord);
