import 'react-datepicker/dist/react-datepicker.css';
import { openNoti } from '../../components/confirmation-popup/Notification';
import Input from '../../components/forms/Input';
import Http from '../../services/Http';
import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Link, withRouter } from 'react-router-dom';

class EditRecord extends Component {
  state = {
    customer: "",
    customers: [],
    plans: [],
    plan: "",
    price: "",
    notes: "",
    otherServices: false,
    startMonth: "",
    endMonth: "",
    errors: {},
    load: true,
    btnLoad: false,
  };

  async componentDidMount() {
    Promise.all([
      await Http.get("customers"),
      await Http.get("plans"),
      await Http.get("bills/" + this.props.match.params.id),
    ]).then((res) => {
      this.setState({
        customers: res[0].data.customers,
        plans: res[1].data.plans,
        customer: res[2].data.bill.customer._id,
        plan: res[2].data.bill.plan._id,
        price: res[2].data.bill.price.egp,
        notes: res[2].data.bill.notes,
        startMonth: new Date(res[2].data.bill.startMonth),
        endMonth: new Date(res[2].data.bill.endMonth),
        load: false,
      });
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getCustomer = (value) => {
    this.setState({ customer: value });

    let customer = this.state.customers.find((c) => c._id === value);

    if (customer.plan && customer.plan._id) {
      let plan = [...this.state.plans].find((p) => p._id === customer.plan._id);

      this.setState({
        plan: plan._id,
        price: plan.price.egp.monthly,
      });
    } else {
      this.setState({
        plan: "5f94be4c466f1251a5ace1f9",
        price: 0,
      });
    }
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new brand
    const bill = {
      customer: this.state.customer,
      startMonth: this.state.startMonth,
      endMonth: this.state.endMonth,
      notes: this.state.notes,
      plan: this.state.plan,
      price: {
        egp: this.state.price,
      },
      otherServices: this.state.otherServices,
    };

    await Http.put("bills/" + this.props.match.params.id, bill)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/bills");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { Option } = Select;
    const {
      errors,
      btnLoad,
      customer,
      customers,
      plans,
      plan,
      price,
      notes,
      startMonth,
      endMonth,
      load,
    } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Update bill</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/bills" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createBrand}>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="label-header">
                      <label className="custom-label" htmlFor="plan">
                        Start date
                        <span className="required required-star">*</span>
                      </label>
                    </div>
                    <DatePicker
                      selected={startMonth}
                      onChange={(date) => this.setState({ startMonth: date })}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="label-header">
                      <label className="custom-label" htmlFor="plan">
                        End date
                        <span className="required required-star">*</span>
                      </label>
                    </div>
                    <DatePicker
                      selected={endMonth}
                      onChange={(date) => this.setState({ endMonth: date })}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <Input
                    label="Notes"
                    placeholder="Notes"
                    type="text"
                    error={errors["notes"] ? errors["notes"].message : false}
                    onChange={this.getData}
                    name="notes"
                    defaultValue={notes}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="label-header">
                      <label className="custom-label" htmlFor="plan">
                        Customer
                        <span className="required required-star">*</span>
                      </label>
                    </div>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select a customer"
                      optionFilterProp="children"
                      defaultValue={customer}
                      onChange={(value) => this.getCustomer(value)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {customers.map((c) => (
                        <Option value={c._id} key={c._id}>
                          {c.marketName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="col-md-4">
                  <Input
                    label="Plan"
                    type="select"
                    fOption="Select plan"
                    options={plans}
                    required
                    error={errors["plan"] ? errors["plan"].message : false}
                    onChange={this.getData}
                    name="plan"
                    value={plan}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    label="Price"
                    placeholder="price"
                    type="number"
                    error={errors["price"] ? errors["price"].message : false}
                    onChange={this.getData}
                    name="price"
                    value={price}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(EditRecord);
