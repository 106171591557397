import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Http from "../../services/Http";
import ContentLoader from "../../components/loaders/ContentLoader";
import dayjs from "dayjs";

class Records extends Component {
  state = {
    movements: [],
    storage: 0,
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([Http.get("movements"), Http.get("storage")]).then((res) => {
      this.setState({
        movements: res[0].data.movements,
        storage: res[0].data.storage?.total,
        load: true,
      });
    });
  };

  render() {
    const { movements, load } = this.state;

    const columns = [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <span>{dayjs(text).format("MM-DD-YYYY")}</span>
        ),
      },
      {
        title: "value",
        dataIndex: "value",
        key: "value",
      },
      {
        title: "notes",
        dataIndex: "notes",
        key: "notes",
      },
      {
        title: "storage",
        dataIndex: "storage",
        key: "storage",
      },
      {
        title: "type",
        dataIndex: "type",
        key: "type",
        render: (text) => (
          <span>
            {text === 1 ? (
              <span className="btn btn-sm btn-success">Revenue</span>
            ) : (
              <span className="btn btn-sm btn-warning">Expence</span>
            )}
          </span>
        ),
      },

      // {
      //   title: "action",
      //   key: "action",
      //   render: (text, record) => (
      //     <span>
      //       <Link
      //         to={`/panel/categories/${record._id}/edit/`}
      //         className="btn btn-sm btn-warning"
      //       >
      //         <MdEdit />
      //       </Link>

      //       <Popconfirm
      //         title="Are you sure delete this?"
      //         onConfirm={() => this.deleteRecord(record._id)}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //         <button className="btn btn-sm btn-danger">
      //           <MdDelete />
      //         </button>
      //       </Popconfirm>
      //     </span>
      //   ),
      // },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Movements</h6>
            <Link to="/panel/movements/add" className="btn btn-primary">
              New movemnt
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={movements}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
