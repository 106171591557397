import jwt from "jsonwebtoken";
// import { logout } from "../services/user";

export function checkToken() {
  let token = window.localStorage.getItem("super_token");
  let user = jwt.decode();
  if (!token || user?.exp * 1000 > Date.now()) {
    // logout();
  } else return true;
}
