import { useEffect, useState } from "react";
import Http from "../../services/Http";

export default function EiditSubscription() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoad(true);
    Http.get("/listAffiliate").then((res) => {
      console.log(res?.data?.record);
      setData(res?.data?.record);
      setLoad(false);
    });
  };
  return <div>omer error</div>;
}
