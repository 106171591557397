import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../../components/forms/Input";
import { openNoti } from "../../../components/confirmation-popup/Notification";
import ReactFileReader from "react-file-reader";
import Http from "../../../services/Http";
import { groupBy } from "../../../helpers/groupBy";
import { Radio } from "antd";

class AddRecord extends Component {
  state = {
    code: 0,
    category: "",
    categories: [],
    selected: [],
    components: {},
    cover: false,
    preview: false,
    errors: {},
    load: true,
    btnLoad: false,
  };

  componentDidMount() {
    Promise.all([Http.get("categories"), Http.get("components")]).then(
      (res) => {
        res[0].data.categories.map((c) => (c.value = c._id));
        this.setState({
          categories: res[0].data.categories,
          components: groupBy(res[1].data.data, "type"),
          load: false,
        });
      }
    );
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getComponent = (component) => {
    let selected = [...this.state.selected];
    let target = selected.find((item) => item.type === component.type);

    if (target) {
      selected[selected.indexOf(target)] = component;
    } else {
      selected.push(component);
    }

    this.setState({ selected: selected });
  };

  handleImage = (files) => {
    this.setState({ cover: files[0], preview: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ cover: false, preview: false });

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    const payload = new FormData();
    if (this.state.cover) payload.append("cover", this.state.cover);
    payload.append("code", this.state.code);
    let filterd = [];
    this.state.selected.map((item) => filterd.push(item._id));
    payload.append("components", filterd);
    if (this.state.category) payload.append("category", this.state.category);

    Http.post("templates", payload)
      .then(async (res) => {
        openNoti(res.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/templates");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const {
      errors,
      btnLoad,
      preview,
      categories,
      components,
      load,
    } = this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-title">
          <h6>Add Template</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/components" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createRecord}>
              <div className="row align-items-center">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <ReactFileReader
                    handleFiles={this.handleImage}
                    fileTypes={[".jpg", ".png", "jpeg"]}
                  >
                    <div className="form-group">
                      <div className="image-preview single-blog">
                        <img
                          src={preview.length > 0 ? preview : "/img/logo.png"}
                          alt="motajer"
                          title="image"
                        />
                      </div>
                    </div>
                  </ReactFileReader>
                  {preview && (
                    <button className="remove-image" onClick={this.clearLogo}>
                      <DeleteOutlined />
                    </button>
                  )}
                </div>
                <div className="col-md-3"></div>

                <div className="col-md-6">
                  <Input
                    label="Code"
                    type="number"
                    required
                    error={errors["code"] ? errors["code"].message : false}
                    onChange={this.getData}
                    name="code"
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Category"
                    type="select3"
                    options={categories}
                    fOption="select category"
                    required
                    error={
                      errors["category"] ? errors["category"].message : false
                    }
                    onChange={this.getData}
                    name="category"
                  />
                </div>
                <div className="col-md-12">
                  <h3>Components</h3>
                  {Object.keys(components).map((key) => (
                    <React.Fragment key={key}>
                      <Radio.Group
                        className="row"
                        onChange={(event) =>
                          this.getComponent(event.target.value)
                        }
                      >
                        {components[key].map((component) => (
                          <div className="col-md-6" key={component._id}>
                            <Radio className="mb-2" value={component}>
                              {key + ` - ${component.code}`}
                            </Radio>
                            <img
                              alt="component"
                              src={Http.front_url + component.cover}
                              width="100%"
                            />
                          </div>
                        ))}
                      </Radio.Group>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AddRecord);
