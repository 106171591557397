/**
 * Content loader
 */
/* eslint-disable */
import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ContentLoader = ({ size }) => (
  <div className="iron-progress-bar d-flex justify-content-center align-items-center h-screen">
    <Spin indicator={antIcon} />
  </div>
);

export default ContentLoader;
