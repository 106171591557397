import React, { Component } from "react";
import { IconContext } from "react-icons";
import {
  MdDashboard,
  MdList,
  MdPowerSettingsNew,
  MdPolymer,
  MdAccountCircle,
  MdReceipt,
  MdGroup,
  MdLabel,
  MdModeEdit,
  MdMonetizationOn,
  MdViewModule,
} from "react-icons/md";
import { userRole } from "../../../services/user";
import { Link } from "react-router-dom";
import Http from "../../../services/Http";
import "./style.scss";
import { MenuOutlined } from "@ant-design/icons";

class AdminSidebar extends Component {
  state = {
    details: false,
    storage: 0,
  };

  constructor(props) {
    super(props);
    // Step 1: Create the ref
    this.asideRef = React.createRef();
  }

  toggleDetails = () => {
    this.setState({ details: !this.state.details });
  };

  sidebarToggle = () => {
    let windowWidth = window.innerWidth;
    let sidebar = document.getElementById("admin-layout");

    if (windowWidth < 991) sidebar.classList.toggle("scenario2");
  };

  sidebarToggle2 = () => {
    this.asideRef.current.classList.toggle("scenario3");
  };

  async componentDidMount() {
    const { data } = await Http.get("storage");
    this.setState({ storage: data.storage?.total });
  }

  render() {
    const { storage } = this.state;
    return (
      <>
        <aside ref={this.asideRef} className="relative admin-sidebar" id="admin-sidebar">
          <button type="button" className="absolute -right-5 top-3 z-50" onClick={() => this.sidebarToggle2()}>
            <MenuOutlined />
          </button>
          <div className="admin-logo-cont">
            <img width="150" src="/img/logo.png" className="logo" alt="logo" />
          </div>
          <a className="text-center white brand-logo" target="_blank" rel="noreferrer" href="https://motajer.net">
            Motajer website
          </a>
          <span className="storage">{storage} EGP</span>
          <IconContext.Provider
            value={{
              color: "white",
              className: "icon-class-name",
              size: "1.2em",
            }}
          >
            <ul className="nav flex-column">
              {userRole() != 3 && (
                <React.Fragment>
                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="dashboard" to="/panel">
                      <MdDashboard />
                      <h6>Statistics</h6>
                    </Link>
                  </li>

                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="Features" to="/panel/features">
                      <MdPolymer />
                      <h6>Features</h6>
                    </Link>
                  </li>

                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="Plans" to="/panel/plans">
                      <MdList />
                      <h6>Plans</h6>
                    </Link>
                  </li>

                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="Leads" to="/panel/leads">
                      <MdList />
                      <h6>Leads</h6>
                    </Link>
                  </li>

                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="Plans" to="/panel/customers">
                      <MdAccountCircle />
                      <h6>Customers</h6>
                    </Link>
                  </li>
                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="Bills" to="/panel/bills">
                      <MdReceipt />
                      <h6>Bills</h6>
                    </Link>
                  </li>

                  <li className="nav-item" onClick={this.sidebarToggle}>
                    <Link className="nav-link" title="Stuff" to="/panel/stuff">
                      <MdGroup />
                      <h6>Stuff</h6>
                    </Link>
                  </li>
                </React.Fragment>
              )}

              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Industries" to="/panel/industries">
                  <MdLabel />
                  <h6>Industries</h6>
                </Link>
              </li>
              {/* <li className="nav-item" onClick={this.sidebarToggle}>
              <Link className="nav-link" title="Categories" to="/panel/categories">
              <MdLabel />
              <h6>Categories</h6>
              </Link>
              </li> */}
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Blogs" to="/panel/blogs">
                  <MdModeEdit />
                  <h6>Blogs</h6>
                </Link>
              </li>
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Docs" to="/panel/docs">
                  <MdModeEdit />
                  <h6>Docs</h6>
                </Link>
              </li>
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="works" to="/panel/works">
                  <MdModeEdit />
                  <h6>Works</h6>
                </Link>
              </li>
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Storage" to="/panel/movements">
                  <MdMonetizationOn />
                  <h6>Storage</h6>
                </Link>
              </li>
              {/* <li className="nav-item" onClick={this.sidebarToggle}>
              <Link className="nav-link" title="Blocks" to="/panel/blocks">
              <MdViewModule />
              <h6>Blocks</h6>
              </Link>
              </li> */}
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Products" to="/panel/products">
                  <MdViewModule />
                  <h6>Products</h6>
                </Link>
              </li>
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Affiliate" to="/panel/affiliate">
                  <MdViewModule />
                  <h6>Affiliate</h6>
                </Link>
              </li>
              <li className="nav-item" onClick={this.sidebarToggle}>
                <Link className="nav-link" title="Affiliate" to="/panel/create_subscription">
                  <MdViewModule />
                  <h6>Subscription</h6>
                </Link>
              </li>
              {/* <li className="nav-item" onClick={this.sidebarToggle}>
              <Link className="nav-link" title="Templates" to="/panel/templates">
              <MdColorLens />
                <h6>Templates</h6>
                </Link>
                </li> */}

              <li className="nav-item">
                <div
                  className="nav-link"
                  onClick={() => {
                    localStorage.removeItem("admin_token");
                    window.location.replace("/panel/login");
                  }}
                >
                  <MdPowerSettingsNew />
                  <h6>Logout</h6>
                </div>
              </li>
            </ul>
          </IconContext.Provider>
        </aside>
      </>
    );
  }
}

export default AdminSidebar;
