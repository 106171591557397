import React, { Component } from "react";
import ContentLoader from "../../components/loaders/ContentLoader";
import { Link, withRouter } from "react-router-dom";
import { message, Popconfirm, Table } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import Http from "../../services/Http";

class Records extends Component {
  state = {
    categories: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("subs?category=" + this.props.match.params.id).then((res) => {
      res.data.categories.map((category) => (category.key = category._id));
      this.setState({
        categories: res.data.categories,
        load: true,
      });
    });
  };

  deleteRecord = (id) => {
    Http.delete("subs/" + id)
      .then((res) => {
        this.getData();
        message.success("category has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  render() {
    const { categories, load } = this.state;

    const columns = [
      {
        title: "name",
        dataIndex: "name.ar",
        key: "name.ar",
      },
      {
        title: "name",
        dataIndex: "name.en",
        key: "name.en",
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/panel/categories/${this.props.match.params.id}/subs/${record._id}/edit/`}
              className="btn btn-sm btn-warning"
            >
              <MdEdit />
            </Link>

            <Popconfirm
              title="Are you sure delete this?"
              onConfirm={() => this.deleteRecord(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Subs</h6>
            <Link
              to={`/panel/categories/${this.props.match.params.id}/subs/add`}
              className="btn btn-primary"
            >
              New sub
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={categories}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Records);
