import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../../components/forms/Input";
import { openNoti } from "../../../components/confirmation-popup/Notification";
import Http from "../../../services/Http";

class EditRecord extends Component {
  state = {
    "name.ar": "",
    "name.en": "",

    btnLoad: false,
  };

  async componentDidMount() {
    const { data } = await Http.get(`onDeskCategories/${this.props.match.params.id}`);

    this.setState({
      "name.ar": data.record.name?.ar,
      "name.en": data.record.name?.en,
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    const payload = {
      name: { ar: this.state["name.ar"], en: this.state["name.en"] },
    };

    const { data } = await Http.put(`onDeskCategories/${this.props.match.params.id}`, payload);

    openNoti(data?.message);
    this.setState({ btnLoad: false });
    this.props.history.push("/panel/docs");
  };

  render() {
    const { btnLoad } = this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-name">
          <h6>Update category</h6>
          <div className="admin-name-super2">
            <button type="submit" disabled={btnLoad} onClick={this.updateRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Update
                </span>
              ) : (
                "Update"
              )}
            </button>

            <Link to="/panel/docs" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-6">
                <Input label="name ar" type="text" required value={this.state["name.ar"]} onChange={this.getData} name="name.ar" />
              </div>
              <div className="col-md-6">
                <Input label="name en" type="text" required value={this.state["name.en"]} onChange={this.getData} name="name.en" />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EditRecord);
