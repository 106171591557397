import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import ContentLoader from "../../components/loaders/ContentLoader";
import { system_url, website_url } from "../../helpers/data";
import Http from "../../services/Http";

class Records extends Component {
  state = {
    records: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("products").then((res) => {
      res.data.products.map((record) => (record.key = record._id));
      this.setState({
        records: res.data.products,
        load: true,
      });
    });
  };

  deleteRecord = (id) => {
    Http.delete("products/" + id).then(() => {
      this.getData();
      message.success("item has been deleted");
    });
  };

  render() {
    const { records, load } = this.state;

    const columns = [
      {
        title: "Cover",
        dataIndex: "gallaries",
        key: "gallaries",
        render: (text) => (
          <span>
            <img width="50" src={text && text.length ? system_url + text[0] : "/img/logo.png"} />
          </span>
        ),
      },
      {
        title: "Name En",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["en"]}</span>,
      },
      {
        title: "Name Ar",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text?.["ar"]}</span>,
      },
      {
        title: "industry",
        dataIndex: "industry",
        key: "industry",
        render: (text) => <span>{text?.["name"]}</span>,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text) => <span>{text?.["name"]?.["en"]}</span>,
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text) => <span>{text?.["user"]}</span>,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/panel/products/${record._id}/edit/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-blogs admin-card pd">
          <div className="admin-title">
            <h6>Products</h6>
            <Link to="/panel/products/add" className="btn btn-primary">
              New product
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={records}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
