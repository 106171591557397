import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import { Editor } from "@tinymce/tinymce-react";
import ReactFileReader from "react-file-reader";

class AddRecord extends Component {
  state = {
    "title.ar": "",
    "title.en": "",
    "description.ar": "",
    "description.en": "",
    "content.ar": "",
    "content.en": "",
    cover: false,
    preview: false,
    errors: {},
    btnLoad: false,
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ cover: files[0], preview: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ cover: false, preview: false });

  createRecord = async (event) => {
    const url =
      window.location.hostname.includes("localhost") || window.location.hostname.includes("morseko")
        ? "http://localhost:8000/website/api/v1/"
        : "https://motajer.net/website/api/v1/";

    let header = {
      "x-app-token": "motajerWebsiteToken",
    };

    event.preventDefault();
    this.setState({ btnLoad: true });

    const blog = {
      title: { ar: this.state["title.ar"], en: this.state["title.en"] },
      description: {
        ar: this.state["description.ar"],
        en: this.state["description.en"],
      },
      content: { ar: this.state["content.ar"], en: this.state["content.en"] },
    };

    fetch(url + "blogs", {
      method: "POST",
      headers: {
        "x-app-token": header["x-app-token"],
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blog),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (this.state.cover) {
          let payload = new FormData();
          payload.append("cover", this.state.cover);

          await fetch(url + "blogs/" + res.blog._id, {
            method: "PUT",
            headers: header,
            body: payload,
          });
        }
        openNoti(res.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/blogs");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const { errors, btnLoad, preview } = this.state;
    return (
      <div className="all-blogs admin-card pd">
        <div className="admin-title">
          <h6>Add blog</h6>
          <div className="admin-title-super2">
            <button type="submit" disabled={btnLoad} onClick={this.createRecord} className="btn btn-primary form-button">
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/blogs" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        <div className="admin-form">
          <form onSubmit={this.createRecord}>
            <div className="row align-items-center">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <ReactFileReader handleFiles={this.handleImage} fileTypes={[".jpg", ".png", "jpeg"]}>
                  <div className="form-group">
                    <div className="image-preview single-blog">
                      <img src={preview.length > 0 ? preview : "/img/logo.png"} alt="motajer" title="image" />
                    </div>
                  </div>
                </ReactFileReader>
                {preview && (
                  <button className="remove-image" onClick={this.clearLogo}>
                    <DeleteOutlined />
                  </button>
                )}
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-6">
                <Input
                  label="title ar"
                  type="text"
                  required
                  error={errors["title.ar"] ? errors["title.ar"].message : false}
                  onChange={this.getData}
                  name="title.ar"
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="title en"
                  type="text"
                  required
                  error={errors["title.en"] ? errors["title.en"].message : false}
                  onChange={this.getData}
                  name="title.en"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Content</label>
                  <Editor
                    initialValue={this.state["content.ar"]}
                    apiKey="lase8gh50s3eano5gluqxsg4lghx4d8gshv0xw0gn4sejanq"
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists emoticons directionality link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor  | \
             alignleft aligncenter alignright alignjustify ltr rtl emoticons | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(editor, content) => {
                      this.setState({ "content.ar": editor });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Content en</label>
                  <Editor
                    initialValue={this.state["content.en"]}
                    apiKey="lase8gh50s3eano5gluqxsg4lghx4d8gshv0xw0gn4sejanq"
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists emoticons directionality link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor  | \
             alignleft aligncenter alignright alignjustify ltr rtl emoticons | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(editor, content) => {
                      this.setState({ "content.en": editor });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description ar ( SEO )</label>
                  <textarea onChange={this.getData} className="form-control" name="description.ar" rows={5}></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description en ( SEO )</label>
                  <textarea onChange={this.getData} className="form-control" name="description.en" rows={5}></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(AddRecord);
