import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import { Checkbox, Row, Col } from "antd";

class AddRecord extends Component {
  state = {
    "name.ar": "",
    "name.en": "",
    products: 250,
    "price.egp.monthly": 0,
    "price.egp.yearly": 0,
    load: true,
    code: 0,
    features: [],
    allFeatures: [],
    errors: {},
    btnLoad: false,
  };

  async componentDidMount() {
    const { data } = await Http.get("features");
    let selected = [];
    data.features
      .filter((f) => [101, 102].includes(f.code))
      .map((r) => selected.push(r._id));
    this.setState({
      allFeatures: data.features,
      features: selected,
      load: false,
    });
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getFeatures = (values) => {
    this.setState({ features: values });
  };

  createRecord = async (event) => {
    event.preventDefault();

    this.setState({ btnLoad: true });

    // Create new brand
    const plan = {
      name: { ar: this.state["name.ar"], en: this.state["name.en"] },
      price: {
        egp: {
          monthly: this.state["price.egp.monthly"],
          yearly: this.state["price.egp.yearly"],
        },
      },
      features: this.state.features,
      code: Number(this.state.code),
    };

    await Http.post("plans/", plan)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/plans");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { errors, btnLoad, load } = this.state;
    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>Add plan</h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={this.createRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> Create
                </span>
              ) : (
                "Create"
              )}
            </button>

            <Link to="/panel/plans" className="btn btn-warning">
              Cancel
            </Link>
          </div>
        </div>

        {!load && (
          <div className="admin-form">
            <form onSubmit={this.createBrand}>
              <div className="row align-items-center">
                <div className="col-md-3">
                  <Input
                    label="Engish Name"
                    placeholder="Engish Name"
                    type="text"
                    required
                    error={
                      errors["name.en"] ? errors["name.en"].message : false
                    }
                    onChange={this.getData}
                    name="name.en"
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Arabic Name"
                    placeholder="Arabic Name"
                    type="text"
                    required
                    error={
                      errors["name.ar"] ? errors["name.ar"].message : false
                    }
                    onChange={this.getData}
                    name="name.ar"
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Price EGP M"
                    placeholder="100"
                    type="number"
                    required
                    error={
                      errors["price.egp.monthly"]
                        ? errors["price.egp.monthly"].message
                        : false
                    }
                    onChange={this.getData}
                    name="price.egp.monthly"
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Price EGP Y"
                    placeholder="100"
                    type="number"
                    required
                    error={
                      errors["price.egp.yearly"]
                        ? errors["price.egp.yearly"].message
                        : false
                    }
                    onChange={this.getData}
                    name="price.egp.yearly"
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="code"
                    value={this.state.code}
                    type="number"
                    required
                    error={errors["code"] ? errors["code"].message : false}
                    onChange={this.getData}
                    name="code"
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="products counts"
                    value={this.state.products}
                    type="number"
                    required
                    error={
                      errors["products"] ? errors["products"].message : false
                    }
                    onChange={this.getData}
                    name="products"
                  />
                </div>
                <div className="col-md-12">
                  <label className="custom-label pb-3">
                    Features <span className="required required-star ">*</span>
                  </label>
                  {console.log(this.state.features)}
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={this.getFeatures}
                    defaultValue={this.state.features}
                  >
                    <Row>
                      {this.state.allFeatures.map((f) => (
                        <Col span={8} key={f._id}>
                          <Checkbox value={f._id}>{f.name.en}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AddRecord);
