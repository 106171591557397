import 'react-datepicker/dist/react-datepicker.css';
import { openNoti } from '../../components/confirmation-popup/Notification';
import Input from '../../components/forms/Input';
import Http from '../../services/Http';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Select } from 'antd';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import ReactFileReader from 'react-file-reader';
import { Link, withRouter } from 'react-router-dom';

class AddRecord extends Component {
  state = {
    customer: "",
    newCustomerId: "",

    customers: [],
    plans: [],
    price: "",
    notes: "",
    otherServices: false,
    startMonth: "",
    endMonth: "",
    status: 2,

    adminName: "",
    marketName: "",
    phone: 0,
    logoImage: "",
    logo: "",
    email: 0,
    password: "",
    subdomain: "",
    domain: "",
    plan: "",

    errors: {},
    btnLoad: false,
  };

  async componentDidMount() {
    Promise.all([await Http.get("customers"), await Http.get("plans")]).then(
      (res) => {
        this.setState({
          customers: res[0].data.customers,
          plans: res[1].data.plans,
        });
      }
    );
  }

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleImage = (files) => {
    this.setState({ logo: files[0], logoImage: URL.createObjectURL(files[0]) });
  };

  clearLogo = () => this.setState({ logo: false, logoImage: false });

  getCustomer = (value) => {
    this.setState({ customer: value });

    let customer = this.state.customers.find((c) => c._id === value);

    if (customer.plan && customer.plan._id) {
      let plan = [...this.state.plans].find((p) => p._id === customer.plan._id);

      this.setState({
        plan: plan._id,
        price: plan.price.egp.monthly,
      });
    } else {
      this.setState({
        plan: "5f94be4c466f1251a5ace1f9",
        price: 0,
      });
    }
  };

  handleOption = (value) => {
    this.setState({ status: value });
  };

  createCustomer = async (event) => {
    const {
      adminName,
      marketName,
      phone,
      email,
      password,
      subdomain,
      domain,
      plan,
      logo,
    } = this.state;

    event.preventDefault();

    this.setState({ btnLoad: true });

    // Create new brand
    const customer = {
      adminName,
      marketName,
      phone,
      email,
      password,
      subdomain,
      domain,
      plan,
    };

    await Http.post("customers/", customer)
      .then(async (result) => {
        if (logo) {
          const data = new FormData();
          data.append("logo", logo);
          try {
            await Http.put("customers/" + result.data.customer._id, data);
          } catch (err) {
            console.log(err);
          }
        }
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/customers");
      })
      .catch((err) => {
        if (err.response.data.message)
          message.warning(err.response.data.message);
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  createRecord = async (event) => {
    event.preventDefault();
    this.setState({ btnLoad: true });

    // Create new bill
    const bill = {
      startMonth: this.state.startMonth,
      endMonth: this.state.endMonth,
      status: this.state.status,
      notes: this.state.notes,
      plan: this.state.plan,
      price: {
        egp: this.state.price,
      },
      otherServices: this.state.otherServices,
    };

    if (this.state.status === 2) bill.customer = this.state.customer;
    if (this.state.status === 1) {
      const customer = {
        adminName: this.state.adminName,
        marketName: this.state.marketName,
        phone: this.state.phone,
        email: this.state.email,
        password: this.state.password,
        subdomain: this.state.subdomain,
        domain: this.state.domain,
        plan: this.state.plan,
      };

      await Http.post("customers/", customer).then(async (result) => {
        this.setState({ newCustomerId: result.data.customer._id });
        bill.customer = result.data.customer._id;
        if (this.state.logo) {
          const data = new FormData();
          data.append("logo", this.state.logo);
          try {
            await Http.put("customers/" + result.data.customer._id, data);
          } catch (err) {
            console.log(err);
          }
        }
      });
    }

    await Http.post("bills/", bill)
      .then(async (result) => {
        openNoti(result.data.message);
        this.setState({ btnLoad: false });
        this.props.history.push("/panel/bills");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, btnLoad: false });
      });
  };

  render() {
    const { Option } = Select;
    const { errors, btnLoad, status, logoImage } = this.state;
    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Add bill</h6>
            <div className="admin-title-super2">
              <button
                type="submit"
                disabled={btnLoad}
                onClick={this.createRecord}
                className="btn btn-primary form-button"
              >
                {btnLoad ? (
                  <span>
                    <LoadingOutlined /> Create
                  </span>
                ) : (
                  "Create"
                )}
              </button>

              <Link to="/panel/bills" className="btn btn-warning">
                Cancel
              </Link>
            </div>
          </div>

          <div className="admin-form">
            <form onSubmit={this.createBrand}>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="form-group">
                    <Input
                      label="customer option"
                      required
                      type="select3"
                      defaultValue={2}
                      fOption="choose bill status"
                      onChange={(event) =>
                        this.handleOption(Number(event.target.value))
                      }
                      options={[
                        { name: "new customer", value: 1 },
                        { name: "old customer", value: 2 },
                      ]}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="label-header">
                      <label className="custom-label" htmlFor="plan">
                        Start date
                        <span className="required required-star">*</span>
                      </label>
                    </div>
                    <DatePicker
                      selected={this.state.startMonth}
                      onChange={(date) => this.setState({ startMonth: date })}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="label-header">
                      <label className="custom-label" htmlFor="plan">
                        End date{" "}
                        <span className="required required-star">*</span>
                      </label>
                    </div>
                    <DatePicker
                      selected={this.state.endMonth}
                      onChange={(date) => this.setState({ endMonth: date })}
                      className="form-control"
                    />
                  </div>
                </div>

                {status === 2 && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="label-header">
                          <label className="custom-label" htmlFor="plan">
                            Customer{" "}
                            <span className="required required-star">*</span>
                          </label>
                        </div>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select a customer"
                          optionFilterProp="children"
                          onChange={(value) => this.getCustomer(value)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.customers.map((c) => (
                            <Option value={c._id} key={c._id}>
                              {c.marketName}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="col-md-4">
                  <Input
                    label="Price"
                    placeholder="price"
                    type="number"
                    error={errors["price"] ? errors["price"].message : false}
                    onChange={this.getData}
                    name="price"
                    value={this.state.price}
                  />
                </div>

                <div className="col-md-4">
                  <Input
                    label="Notes"
                    placeholder="Notes"
                    type="text"
                    error={errors["notes"] ? errors["notes"].message : false}
                    onChange={this.getData}
                    name="notes"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        {status === 1 && (
          <div className="all-categories admin-card pd">
            <div className="admin-title">
              <h6>Add customer</h6>
            </div>

            <div className="admin-form">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <ReactFileReader
                    handleFiles={this.handleImage}
                    fileTypes={[".jpg", ".png"]}
                  >
                    <div className="form-group">
                      <div className="image-preview single">
                        <img
                          src={
                            logoImage.length > 0
                              ? logoImage
                              : "/img/placeholder.png"
                          }
                          alt="motajer"
                          title="image"
                        />
                      </div>
                    </div>
                  </ReactFileReader>
                  {logoImage && (
                    <button className="remove-image" onClick={this.clearLogo}>
                      <DeleteOutlined />
                    </button>
                  )}
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-3">
                      <Input
                        label="Market name"
                        placeholder="Motajer"
                        type="text"
                        required
                        error={
                          errors["marketName"]
                            ? errors["marketName"].message
                            : false
                        }
                        onChange={this.getData}
                        name="marketName"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Admin Name"
                        placeholder="Admin Name"
                        type="text"
                        required
                        error={
                          errors["adminName"]
                            ? errors["adminName"].message
                            : false
                        }
                        onChange={this.getData}
                        name="adminName"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Email"
                        placeholder="email"
                        type="email"
                        required
                        error={
                          errors["email"] ? errors["email"].message : false
                        }
                        onChange={this.getData}
                        name="email"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Phone"
                        placeholder="phone"
                        type="tel"
                        required
                        error={
                          errors["phone"] ? errors["phone"].message : false
                        }
                        onChange={this.getData}
                        name="phone"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Subdomain"
                        placeholder="subdomain"
                        type="text"
                        required
                        error={
                          errors["subdomain"]
                            ? errors["subdomain"].message
                            : false
                        }
                        onChange={this.getData}
                        name="subdomain"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Domain"
                        placeholder="domain"
                        type="text"
                        error={
                          errors["domain"] ? errors["domain"].message : false
                        }
                        onChange={this.getData}
                        name="domain"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Password"
                        placeholder="password"
                        type="password"
                        required
                        error={
                          errors["password"]
                            ? errors["password"].message
                            : false
                        }
                        onChange={this.getData}
                        name="password"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        label="Plan"
                        type="select"
                        fOption="Select plan"
                        options={this.state.plans}
                        required
                        error={errors["plan"] ? errors["plan"].message : false}
                        onChange={this.getData}
                        name="plan"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(AddRecord);
