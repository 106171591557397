import ContentLoader from '../../components/loaders/ContentLoader';
import Http from '../../services/Http';
import { message, Table } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Records extends Component {
  state = {
    bills: [],
    load: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("bills").then((res) => {
      res.data.bills.map((bill) => (bill.key = bill._id));
      this.setState({
        bills: res.data.bills,
        load: true,
      });
    });
  };

  deleteRecord = (id) => {
    Http.delete("bills/" + id)
      .then((res) => {
        this.getData();
        message.success("bill has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  render() {
    const { bills, load } = this.state;

    const columns = [
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (text) => <span>{text["marketName"]}</span>,
      },
      {
        title: "Start Month",
        dataIndex: "startMonth",
        key: "startMonth",
        render: (text, record) => (
          <span>{dayjs(text).format("MM-DD-YYYY")}</span>
        ),
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        key: "endMonth",
        render: (text, record) => (
          <span>{dayjs(text).format("MM-DD-YYYY")}</span>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text, record) => (
          <span>{text && text.egp ? text.egp : 0} EGP</span>
        ),
      },

      // {
      //   title: "action",
      //   key: "action",
      //   render: (text, record) => (
      //     <span>
      //       <Link
      //         to={`/panel/bills/${record._id}/edit/`}
      //         className="btn btn-sm btn-warning"
      //       >
      //         <MdEdit />
      //       </Link>

      //       <Popconfirm
      //         title="Are you sure delete this?"
      //         onConfirm={() => this.deleteRecord(record._id)}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //         <button className="btn btn-sm btn-danger">
      //           <MdDelete />
      //         </button>
      //       </Popconfirm>
      //     </span>
      //   ),
      // },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>Bills</h6>
            <Link to="/panel/bills/add" className="btn btn-primary">
              New bill
            </Link>
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={bills}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
